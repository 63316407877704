import React, { useState } from 'react';
import { useClickOutside, useMediaQuery } from '@mantine/hooks';
import { Button, Drawer, Menu, useMantineTheme, NavLink, AppShell } from '@mantine/core';
import { IconHelp, IconLink, IconReceiptRefund } from '@tabler/icons-react';
import { openRequestDrawer } from '@components/ui/drawers/Requests/RequestDrawer';
import classes from '@components/layout/Sidebar.module.css';
import { useFeature } from 'flagged';

export function NavActionBar() {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const ref = useClickOutside(() => setOpened(false));

  const hasRefunds = useFeature('refunds');
  const canCreateHelp = useFeature('help.create');
  const canCreateRefund = useFeature('refund.create');

  if (!canCreateHelp && (!hasRefunds || !canCreateRefund)) {
    return <></>;
  }

  const menuItems = <>
    { canCreateHelp && <>
      <NavLink leftSection={<IconHelp size={18} stroke={1.5} />}
               label="Help Request"
               onClick={() => {
                 openRequestDrawer({ type: 'help' });
                 setOpened(false);
               }}
      />
    </>}

    { hasRefunds && canCreateRefund && <>
      <NavLink leftSection={<IconReceiptRefund size={18} stroke={1.5} />}
               label="Refund Request"
               onClick={() => {
                 openRequestDrawer({ type: 'refund' });
                 setOpened(false);
               }}
      />
    </>}

    { ['development', 'staging'].includes(process.env.NEXT_PUBLIC_ENV) && <>
      <NavLink leftSection={<IconLink size={18} stroke={1.5} />}
               label="Request Link"
               onClick={() => {
                 openRequestDrawer({ type: 'link' });
                 setOpened(false);
               }}
      />
    </>}
  </>;

  return <AppShell.Section className={classes.section}>
    <Menu
      withArrow
      shadow="sm"
      width={200}
      offset={{
        mainAxis: 25,
      }}
      opened={!isMobile && opened}
      position="right-start"
    >
      <Menu.Target>
        <Button variant="outline" fullWidth onClick={() => setOpened(!opened)}>
          Create Request
        </Button>
      </Menu.Target>

      <Menu.Dropdown ref={ref}>
        { menuItems }
      </Menu.Dropdown>
    </Menu>

    <Drawer opened={isMobile && opened}
            ref={ref}
            onClose={() => setOpened(!opened)}
            position="bottom"
            size="25%"
            withCloseButton={false}
            trapFocus
            zIndex={1000}
    >
      { menuItems }
    </Drawer>
  </AppShell.Section>;
}
