import { IResourceComponentsProps } from "@refinedev/core";
import { IMachineTypeResponse } from '@interfaces';
import { EuiCallOut } from '@elastic/eui';
import { Accordion, Card, Group, Stack, Text, Title } from '@mantine/core';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useIdentity } from '@components/data/Identity.context';
import defaultTranslations from './form/default-translations';
import { CommonlyUsedTypes } from './form/CommonlyUsedTypes';
import { DetailsForm } from './form/DetailsForm';
import { useMachineTypePresetOptions } from '@components/hooks';

export function MachineTypeEdit({ initialData: record }: IResourceComponentsProps<IMachineTypeResponse>) {
  const { identity } = useIdentity();
  const { data: presets, isLoading: isPresetsLoading } = useMachineTypePresetOptions();

  const translations = record?.data.translations || defaultTranslations;

  const schema = Yup.object().shape({
    roles: Yup.array(),
    owner_id: Yup.mixed().nullable().when('roles', {
      is: (roles) => roles.includes('Admin'),
      then: (schema) => schema.required('Assign an owner')
    }),
    type: Yup.string().required('Enter a name'),
    is_active: Yup.bool(),
    translations: Yup.lazy(() => {
      const rules = (identity?.owner.supported_languages || []).reduce((rules, lang) => {
        rules[lang] = Yup.string().nullable().when({
          is: () => identity?.owner?.supported_languages.length > 1,
          then: (schema) => schema.required('Text is required'),
        });


        return rules;
      }, {});

      return Yup.object(rules).required();
    }),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      roles: identity.roles,
      owner_id: record?.data.owner_id.toString() ?? null,
      type: record?.data.type ?? '',
      is_active: record?.data.is_active ?? true,
      translations: {
        en: translations.en ?? '',
        es: translations.es ?? '',
        fr: translations.fr ?? '',
      },
    },
  });

  const hasPresets = !!presets?.data.length;

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>Machine Type</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        { !isPresetsLoading && <>
          { !record && hasPresets && <Stack>
            <CommonlyUsedTypes presets={presets?.data} />

            <Accordion
              variant="separated"
              chevronPosition="left"
              chevron={<IconPlus size="1rem" />}
              styles={(theme) => ({
                control: { backgroundColor: theme.colors.gray[1] },
                item: { border: 0 },
                content: { padding: 0 },
                chevron: {
                  '&[dataRotate]': {
                    transform: 'rotate(45deg)',
                  },
                },
              })}
            >
              <Accordion.Item value="custom">
                <Accordion.Control>Add custom</Accordion.Control>
                <Accordion.Panel>
                  <Stack mt="md">
                    <EuiCallOut title={<Text size="sm" fw="500" span>HINT: it is recommended to keep the list of machine types short to make it easier for customers to make a selection.</Text>}
                                iconType="help"></EuiCallOut>

                    <DetailsForm />
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Stack>}

          { (record || !hasPresets) && <DetailsForm /> }
        </>}
      </Card.Section>
    </Card>
  </ResourceForm>
}
