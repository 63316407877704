import { Group, Text, Tooltip } from '@mantine/core';
import { EuiBasicTableColumn } from '@elastic/eui';
import { ITagListItem } from '@interfaces';
import { DateTime } from '@components/ui/DateTime';
import { IconLink } from '@tabler/icons-react';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'identifier',
    name: 'Tag ID',
    width: '180',
    sortable: true,
    truncateText: true,
    render: (_value, record) => {
      const tagId = record.asset_id === record.identifier ? record.asset_id : record.identifier;

      return <Tooltip
        label={`Equipment is managed by ${record.integration_platform}`}
        position="right" withArrow arrowSize={6}
        disabled={!record.integration_platform}
      >
        <Group gap={4} w={{ xs: 'auto', sm: '100%' }}>
          <Text truncate="end" flex={1} ml={5} title={tagId}>{tagId}</Text>

          {record.integration_platform && <IconLink
            size={15}
            strokeWidth={1.5}
            color="#07C"
          />}
        </Group>
      </Tooltip>;
    }
  },
  {
    field: 'name',
    name: 'Name',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'location_name',
    name: 'Location Name',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'account_name',
    name: 'Account Name',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'created_at',
    name: 'Added',
    width: '160',
    sortable: true,
    dataType: 'date',
    render: (value) => <DateTime value={value} />
  },
  {
    field: 'help_requests_count',
    name: 'Help',
    width: '75',
    align: 'center',
    sortable: true,
  },
  {
    field: 'voice_messages_count',
    name: 'Calls',
    width: '85',
    align: 'center',
    sortable: true,
    permissions: ['voice_messages', 'admin']
  },
  {
    field: 'feedback_requests_count',
    name: 'Feedback',
    width: '85',
    align: 'center',
    sortable: true,
  },
  {
    field: 'refunds_count',
    name: 'Refunds',
    width: '80',
    align: 'center',
    sortable: true,
    permissions: ['refunds', 'admin']
  },
  {
    field: 'refunds_sum_amount',
    name: 'Requested',
    width: '100',
    align: 'center',
    sortable: true,
    render: (value) => `$${value || 0}`,
    permissions: ['refunds', 'admin']
  },
] as EuiBasicTableColumn<ITagListItem>[];
