import { Button, Card, Group, Title, Text, Box } from '@mantine/core';
import { useIdentity } from '@components/data/Identity.context';
import { useUpdate } from '@refinedev/core';
import { EuiIcon } from '@elastic/eui';

export const PreAuthSettingsForm = () => {
  const { identity, refresh } = useIdentity();
  const { mutate: update, isLoading } = useUpdate();

  const owner = identity?.owner;

  const handleSubmit = ({ active }) => {
    const data = {
      settings: {
        'refunds_has-pre-auth-enabled': active,
      }
    }

    update({
      resource: 'owner/settings', id: identity.owner.id, values: data,
      successNotification: { type: 'success', message: 'Successfully updated settings.' }
    }, {
      onSuccess: refresh
    });
  };

  return <>
    <Box my="md">
      { !owner.settings['refunds_has-pre-auth-enabled'] && <>
        <Card withBorder p="md" radius="sm">
          <Card.Section p="md" withBorder>
            <Title order={5} fw="500">Activate</Title>
          </Card.Section>

          <Card.Section withBorder p="md">
            <Text size="sm">
              Payment methods support pre-authorization and enable payment reversal. To enable pre-authorization, add a
              new payment method for each payment method you support, and activate pre-auth to each that applies.
              Change the refund type to “Reversal” to issue reversals for the payment method instead of refunds.
            </Text>
          </Card.Section>

          <Card.Section px="md" py="sm">
            <Group justify="right">
              <Button type="submit"
                      variant="light"
                      onClick={() => handleSubmit({ active: true })}
                      loading={isLoading}>
                I agree and activate
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </>}

      { owner.settings['refunds_has-pre-auth-enabled'] && <>
        <Card withBorder p="md" radius="sm">
          <Card.Section p="md" withBorder>
            <Group justify="space-between">
              <Title order={5} fw="500">
                <EuiIcon type="dot" color="#42ba96" size="l" /> Payment Methods Enabled
              </Title>
              <Button type="submit"
                      color="red"
                      variant="light"
                      onClick={() => handleSubmit({ active: false })}
                      loading={isLoading}>
                Deactivate
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </>}
    </Box>
  </>;
}
