import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IOwnerRecord } from '@interfaces';
import { EuiCallOut } from '@elastic/eui';
import { Divider, Input, Text, TextInput } from '@mantine/core';
import RichTextEditor from '@components/ui/editors/RichTextEditor';

export const AppContentForm = () => {
  const { form } = useResourceForm<IOwnerRecord>();

  return <>
    <EuiCallOut>
      <Text size="sm">
        You may already have existing pages we can link the following to, otherwise content can be provided instead. If a URL is populated, then
        that will be used over any existing content.
      </Text>
    </EuiCallOut>

    <TextInput label="About us URL" {...form.getInputProps('settings.content_url_about-us')} />

    <TextInput label="Terms of Use URL" {...form.getInputProps('settings.content_url_terms-of-use')} />

    <TextInput label="Privacy Policy URL" {...form.getInputProps('settings.content_url_privacy-policy')} />

    <Divider label="Or" labelPosition="center" mt="md" mb="xs" />

    <Input.Wrapper label="About us">
      <RichTextEditor placeholder="Add some content..." {...form.getInputProps('about_us')} />
    </Input.Wrapper>
    <Input.Wrapper label="Terms of Use">
      <RichTextEditor placeholder="Add some content..." {...form.getInputProps('term_of_use')} />
    </Input.Wrapper>
    <Input.Wrapper label="Privacy Policy">
      <RichTextEditor placeholder="Add some content..." {...form.getInputProps('private_policy')} />
    </Input.Wrapper>
  </>;
}
