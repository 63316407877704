import { CollapsableCard } from '@components/ui';
import { IRefundRecord } from '@interfaces';
import dayjs from 'dayjs';
import { IconCancel, IconCalendarTime, IconSend } from '@tabler/icons-react';
import { Button, Group, TextInput } from '@mantine/core';
import { useSendPreAuthFollowUpSMS } from '@components/hooks';
import { EuiPopover } from '@elastic/eui';
import { useDisclosure } from '@mantine/hooks';
import { useCancelPreAuthFollowUpSMS } from '@components/hooks/useCancelPreAuthFollowUpSMS';

type Props = {
  refund: IRefundRecord;
}

export const PreAuthFollowUpCard = ({ refund }: Props) => {
  const { send, isLoading: sendIsLoading } = useSendPreAuthFollowUpSMS();
  const { cancel, isLoading: cancelIsLoading } = useCancelPreAuthFollowUpSMS();
  const [sendOpened, sendHandlers] = useDisclosure(false);
  const [cancelOpened, cancelHandlers] = useDisclosure(false);

  const handleSendTrigger = () => {
    sendHandlers.close();
    send(refund.id);
  };

  const handleCancelTrigger = () => {
    cancelHandlers.close();
    cancel(refund.id);
  };

  return <CollapsableCard label="Pre-auth hold ends" collapsable={false}>
    { !refund.meta.follow_up_sent_at && !refund.meta.follow_up_cancelled_at && <>
      <Group wrap="nowrap" align="start" gap="sm">
        <TextInput flex={1}
                   description="Follow up notification scheduled to be sent"
                   value={dayjs(refund.meta.follow_up_scheduled_at).format('D MMM \'YY h:mma')}
                   readOnly
                   leftSection={<IconCalendarTime />} />

        <EuiPopover
          panelPaddingSize="s"
          isOpen={sendOpened}
          closePopover={sendHandlers.close}
          button={<Button variant="filled"
                    leftSection={<IconSend size={16}/>}
                    loading={sendIsLoading}
                    onClick={sendHandlers.open}>Send now</Button>}
        >
          <Group wrap="nowrap" gap="xs">
            <Button variant="default" onClick={sendHandlers.close}>Cancel</Button>
            <Button variant="outline" onClick={handleSendTrigger}>Confirm</Button>
          </Group>
        </EuiPopover>

        <EuiPopover
          panelPaddingSize="s"
          isOpen={cancelOpened}
          closePopover={cancelHandlers.close}
          button={<Button variant="filled"
                          color="red"
                          leftSection={<IconCancel size={16}/>}
                          loading={cancelIsLoading}
                          onClick={cancelHandlers.open}>Cancel</Button>}
        >
          <Group wrap="nowrap" gap="xs">
            <Button variant="default" onClick={cancelHandlers.close}>Cancel</Button>
            <Button variant="outline" onClick={handleCancelTrigger}>Confirm</Button>
          </Group>
        </EuiPopover>
      </Group>
    </>}

    { refund.meta.follow_up_sent_at && <>
      <TextInput description="Follow up notification message sent"
                 value={dayjs(refund.meta.follow_up_sent_at).format('D MMM \'YY h:mma')}
                 readOnly
                 leftSection={<IconCalendarTime />} />
    </>}

    { refund.meta.follow_up_cancelled_at && <>
      <TextInput description="Follow up notification message cancelled"
                 value={dayjs(refund.meta.follow_up_cancelled_at).format('D MMM \'YY h:mma')}
                 readOnly
                 leftSection={<IconCalendarTime />} />
    </>}
  </CollapsableCard>;
}
