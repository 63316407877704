import { Box, Card, Center, Drawer, Group, Loader, Text, Button } from '@mantine/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { euiDragDropReorder } from '@elastic/eui';
import { IconGripVertical } from '@tabler/icons-react';
import { useApiUrl, useCustom, useCustomMutation, useInvalidate, useResource } from '@refinedev/core';
import { useEffect, useState } from 'react';

export const ResourceSortable = ({ closeDrawer }) => {
  const apiUrl = useApiUrl();
  const resource = useResource();
  const [items, setItems] = useState(null);
  const invalidate = useInvalidate();

  const { data, isLoading, refetch } = useCustom<[]>({
    url: `${apiUrl}/${resource.resource.name}/list`,
    method: 'get',
  });

  const { mutate, isLoading: isSaving } = useCustomMutation();

  useEffect(() => {
    if (data) {
      setItems(data.data);
    }
  }, [data]);

  const onDragEnd = (source, destination) => {
    if (source && destination) {
      const list = euiDragDropReorder(items, source.index, destination.index);

      setItems(list);

      const ids = list.map(i => i.id);
      mutate({ url: `${apiUrl}/${resource.resource.name}/reorder`, method: 'put', values: { ids } }, {
        onSettled: () => {
          invalidate({ resource: resource.resource.name, invalidates: ['all'] })
          refetch()
        },
      });
    }
  };

  if (isLoading) {
    return <Center style={{ width: '100%' }}>
      <Loader />
    </Center>;
  }

  if (!items) {
    return <></>;
  }

  const fields = (items || []).map((item, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {(provided) => <Box ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
        <Card withBorder p="xs" {...provided.dragHandleProps}>
          <Group>
            <Center>
              <IconGripVertical size={18} />
            </Center>

            <Text size="md">{ item['name'] || item['type'] }</Text>
          </Group>
        </Card>
      </Box>}
    </Draggable>
  ));

  return <>
    <Drawer.Header px={0}>
      <Button variant="outline"
              loading={isSaving}
              onClick={closeDrawer}>
        { isSaving ? 'Saving': 'Done'}
      </Button>
      <Drawer.CloseButton />
    </Drawer.Header>
    <DragDropContext
      onDragEnd={({ destination, source }) => onDragEnd(source, destination)}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {fields}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </>;
}
