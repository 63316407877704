import { IResourceComponentsProps } from "@refinedev/core";
import { IOwnerResponse } from '@interfaces';
import {
  Card,
  Divider,
  Group,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Title,
  Box,
  NumberInput,
  Text, MultiSelect, TagsInput
} from '@mantine/core';
import { IconListDetails, IconLock, IconMessageCircle, IconSettings, IconDeviceMobileMessage, IconBooks } from '@tabler/icons-react';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { ColorSwatchInput } from '@components/ui/colorGenerator/ColorSwatchInput';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { ALL_TIMEZONES } from '@components/ui/timezones';
import { SMSNotificationSettings } from '../../app_settings/app_content/form/SMSNotificationSettings';
import { TranslateEditor } from '@components/ui/editors/TranslateEditor';
import { AppContentForm } from '../../app_settings/app_content/form/AppContentForm';
import { OrgSettingsForm } from '../../settings/organization/form/OrgSettingsForm';
import { AppContentTranslations } from '../../app_settings/app_content/form/AppContentTranslations';
import { Tabs } from '@components/ui/tabs/Tabs';

import contentSchema from '../../app_settings/app_content/form/form-validation-schema';
import orgSchema from '../../settings/organization/form/form-validation-schema';
import { useIdentity } from '@components/data/Identity.context';
import { ImageSelectorInput } from '@components/ui/imageSelectorInput/ImageSelectorInput';

const schema = Yup.object().shape({
  ...contentSchema.fields,
  ...orgSchema.fields,
  is_onboarding: Yup.bool(),
  is_active: Yup.bool(),
  stripe_id: Yup.string().when({
    is: (val) => !!val,
    then: (schema) => schema.matches(/^cus_[A-Za-z0-9]{13,}$/ , 'Wrong format'),
  }),
  sms_code: Yup.string().required('Enter a sms code'),
  settings: Yup.object({
    'email_recipients_bcc': Yup.array(),
    'notification_customer_channels': Yup.array().min(1).required('Select at least 1'),
    ...contentSchema.fields.settings['fields'],
    ...orgSchema.fields.settings['fields'],
  }),
});

export function OwnerEdit({ initialData: record }: IResourceComponentsProps<IOwnerResponse>) {
  const { identity } = useIdentity();

  const bccEmails = record?.data.settings['email_recipients_bcc'] ?? [];
  const phoneNumbers = record?.data.phone_numbers ?? [];

  const phoneOptions = phoneNumbers.map(v => ({ value: v.phone_number, label: v.phone_number }));

  const defaultTagDetailsFormat = '<strong>{tag.name}</strong> {common.words.at} {tag.address}';

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      name: record?.data.name ?? '',
      timezone: record?.data.timezone ?? '',
      logo_path: record?.data.logo_path ?? '',
      theme_color: record?.data.theme_color ?? '#000000',
      country_code: record?.data.country_code ?? 'US',
      company_url_slug: record?.data.company_url_slug ?? '',
      sms_code: record?.data.sms_code ?? '',
      about_us: record?.data.about_us ?? '',
      term_of_use: record?.data.term_of_use ?? '',
      private_policy: record?.data.private_policy ?? '',
      translations: record?.data.translations ?? identity.default_translations,
      supported_languages: record?.data.supported_languages ?? ['en'],
      sms_languages: record?.data.sms_languages ?? ['en'],
      require_machine_id_title_flg: record?.data.require_machine_id_title_flg ?? false,
      require_description_flg: record?.data.require_description_flg ?? false,
      require_image_flg: record?.data.require_image_flg ?? false,
      require_machine_id_in_refund_flg: record?.data.require_machine_id_in_refund_flg ?? false,
      require_photo_in_refund_flg: record?.data.require_photo_in_refund_flg ?? false,
      phone_numbers: record?.data.phone_numbers?.map(v => v.phone_number) ?? [],
      machine_types: record?.data.machine_types?.map(v => v.id) ?? [],
      is_onboarding: record?.data.is_onboarding ?? false,
      is_active: record?.data.is_active ?? true,
      stripe_id: record?.data.stripe_id ?? '',
      settings: {
        'content_url_about-us': record?.data.settings['content_url_about-us'] ?? '',
        'content_url_terms-of-use': record?.data.settings['content_url_terms-of-use'] ?? '',
        'content_url_privacy-policy': record?.data.settings['content_url_privacy-policy'] ?? '',
        'content_tag_details-format': record?.data.settings['content_tag_details-format'] ?? defaultTagDetailsFormat,
        'content_tag_details-hidden': record?.data.settings['content_tag_details-hidden'] ?? false,
        'request_sms_send-help-request-received': record?.data.settings['request_sms_send-help-request-received'] ?? false,
        'request_sms_send-help-request-closed': record?.data.settings['request_sms_send-help-request-closed'] ?? false,
        'request_sms_send-feedback-request-received': record?.data.settings['request_sms_send-feedback-request-received'] ?? false,
        'request_sms_send-refund-details-received': record?.data.settings['request_sms_send-refund-details-received'] ?? false,
        'request_sms_send-refund-approved': record?.data.settings['request_sms_send-refund-approved'] ?? false,
        'request_sms_send-refund-declined': record?.data.settings['request_sms_send-refund-declined'] ?? false,
        'request_email_include-customer-in-reply-to': record?.data.settings['request_email_include-customer-in-reply-to'] ?? false,
        'request_email_additional-subject-line-references': record?.data.settings['request_email_additional-subject-line-references'] ?? [],
        'refunds_has-pre-auth-enabled': record?.data.settings['refunds_has-pre-auth-enabled'] ?? true,
        refunds_active: record?.data.settings.refunds_active ?? false,
        'refunds_max-amount': record?.data.settings['refunds_max-amount'] ?? 20,
        'refunds_allow_vopay': record?.data.settings['refunds_allow_vopay'] ?? false,
        'refunds_allow_automation': record?.data.settings['refunds_allow_automation'] ?? false,
        'machine-location_field_active': record?.data.settings['machine-location_field_active'] ?? false,
        'email_recipients_bcc': bccEmails,
        customers_confirmation_active: record?.data.settings.customers_confirmation_active ?? false,
        notification_customer_channels: record?.data.settings.notification_customer_channels ?? ['email'],
        refunds_expiration_active: record?.data.settings.refunds_expiration_active ?? false,
        refunds_expiration_period: record?.data.settings.refunds_expiration_period ?? 7,
        'refunds_expiration_auto-close-help': record?.data.settings['refunds_expiration_auto-close-help'] ?? false,
      },
      features: {
        integrations: record?.data.features.integrations ?? true,
        refunds: record?.data.features.refunds ?? true,
        voice_messages: record?.data.features.voice_messages ?? true,
        webhooks: record?.data.features.webhooks ?? true,
        tag_limit: record?.data.features.tag_limit ?? null,
        user_limit: record?.data.features.user_limit ?? null,
      },
      wallet: {
        meta: {
          credit: record?.data.wallet.meta.credit ?? 0,
        }
      }
    },
  });

  return <ResourceForm form={form} size="md">
    <Group mb="lg">
      <Title order={2}>Owner</Title>
    </Group>

    <Tabs variant="outline" defaultValue="details" mt="xl"
          withHash
          styles={(theme) => ({
            panel: {
              border: `1px solid ${theme.colors['gray'][3]}`,
              borderTop: 0,
            }
          })}
    >
      <Tabs.List>
        <Tabs.Tab value="details" leftSection={<IconListDetails size={14} />}>Details</Tabs.Tab>
        <Tabs.Tab value="content" leftSection={<IconMessageCircle size={14} />}>Content</Tabs.Tab>
        <Tabs.Tab value="translations" leftSection={<IconBooks size={14} />}>Translations</Tabs.Tab>
        <Tabs.Tab value="settings" leftSection={<IconSettings size={14} />}>Settings</Tabs.Tab>
        <Tabs.Tab value="notifications" leftSection={<IconDeviceMobileMessage size={14} />}>Notifications</Tabs.Tab>
        <Tabs.Tab value="features" leftSection={<IconLock size={14} />}>Features</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="details" p="md">
        <Stack gap="sm">
          <SimpleGrid cols={{ sm: 2 }} spacing="sm">
            <TextInput label="Name" {...form.getInputProps('name')} />

            <Select
              label="Timezone"
              searchable
              nothingFoundMessage="Nothing found"
              data={ALL_TIMEZONES}
              {...form.getInputProps('timezone')}
            />

            <TextInput label="Stripe ID" {...form.getInputProps('stripe_id')} />

            <TagsInput
              label="BCC Emails"
              data={bccEmails}
              placeholder="Add or select an email..."
              {...form.getInputProps('settings.email_recipients_bcc')}
            />

            <TextInput label="SMS Code" {...form.getInputProps('sms_code')} />

            <TextInput label="Company URL Slug"
                       description="Should only contain alphanumeric, - or _"
                       {...form.getInputProps('company_url_slug')} />

            <Select label="Country"
                    data={[
                      { value: 'US', label: 'US' },
                      { value: 'CA', label: 'CA' },
                    ]}
                    {...form.getInputProps('country_code')}
            />

            <TagsInput
              label="Phone Numbers"
              data={phoneOptions}
              placeholder="Enter a phone number"
              {...form.getInputProps('phone_numbers')} />

            <ImageSelectorInput label="Logo" {...form.getInputProps('logo_path')} imageProps={{ mah: 85, maw: 220 }} />
          </SimpleGrid>

          <ColorSwatchInput label="Theme Color" {...form.getInputProps('theme_color')} />

          <Divider my="md" />

          <SimpleGrid cols={{ md: 2 }} spacing="lg">
            <Switch label="Equipment details are hidden"
                    description="Hides the formatted details on the equipment card in the App"
                    offLabel="No" onLabel="Yes"
                    {...form.getInputProps('settings.content_tag_details-hidden', { type: 'checkbox' })}
            />

            { !form.values.settings['content_tag_details-hidden'] && <Box mt={-4}>
              <TranslateEditor
                label="Equipment Details Format"
                originalText={defaultTagDetailsFormat}
                tokens={[
                  '{tag.name}', '{tag.account_name}', '{tag.location_id}', '{tag.location_name}',
                  '{tag.identifier}', '{tag.description}', '{tag.address}', '{tag.type}', '{common.words.at}',
                ]}
                {...form.getInputProps(`settings.content_tag_details-format`)}
              />
            </Box>}
          </SimpleGrid>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="content" p="md">
        <Stack gap="sm">
          <AppContentForm />
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="translations" p="md">
        <Stack gap="sm">
          <AppContentTranslations />
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="settings" p="md">
        <Stack gap="sm">
          <Stack gap={0}>
            <Title order={5}>Customer notifications</Title>
          </Stack>

          <SimpleGrid cols={{ sm: 2 }} spacing="lg">
            <MultiSelect
              label="Enabled channels"
              data={['sms', 'email']}
              {...form.getInputProps('settings.notification_customer_channels')} />
          </SimpleGrid>

          <Divider my="md" />

          <OrgSettingsForm />

          <Divider my="md" />

          <SimpleGrid cols={{ sm: 2 }} spacing="lg">
            <NumberInput label="Maximum refund amount" max={1000} {...form.getInputProps('settings.refunds_max-amount')} />
          </SimpleGrid>

          <Divider my="md" />

          <Stack gap={0} mb="xs">
            <Title order={5}>Wallet settings</Title>
            <Text size="sm" c="dimmed">These settings only apply to owners that have a configured stripe customer</Text>
          </Stack>

          <SimpleGrid cols={{ sm: 2 }} spacing="lg">
            <NumberInput label="Credit limit" {...form.getInputProps('wallet.meta.credit')} />
          </SimpleGrid>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="notifications" p="md">
        <Stack gap="sm">
          <SMSNotificationSettings />
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="features" p="md">
        <SimpleGrid cols={{ md: 2 }} spacing="lg">
          <Switch label="Enable Webhook feature"
                  description="Enables Webhook access and functionality."
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('features.webhooks', { type: 'checkbox' })} />

          <Switch label="Enable Integration feature"
                  description="Enables Integration access and functionality. (VMS)"
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('features.integrations', { type: 'checkbox' })} />

          <Switch label="Enable Voice Messages feature"
                  description="Enables Voice Messages access and functionality."
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('features.voice_messages', { type: 'checkbox' })} />
        </SimpleGrid>

        <Divider my="md" />

        <SimpleGrid cols={{ md: 2 }} spacing="lg">
          <Switch label="Enable Refund feature"
                  description="Enables Refund access and functionality."
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('features.refunds', { type: 'checkbox' })} />

          <Switch label="Refunds active"
                  description="Allows customers to enter their refund details into the App."
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('settings.refunds_active', { type: 'checkbox' })} />

          <Switch label="Refund payment method flow (Pre-auth)"
                  description="Enable / Disable the additional payment method flow"
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('settings.refunds_has-pre-auth-enabled', { type: 'checkbox' })} />

          <Switch label="Allow VoPay intergration for Interac refund method"
                  description="Enable / Disable the use of VoPay for automated Interac refunds (Note: Should only be enabled for Canteen Canada)"
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('settings.refunds_allow_vopay', { type: 'checkbox' })} />

          <Switch label="Allow refund automation"
                  description="Enable / Disable the use of automated approval of refunds"
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('settings.refunds_allow_automation', { type: 'checkbox' })} />
        </SimpleGrid>

        <Divider my="md" />

        <SimpleGrid cols={{ md: 2 }} spacing="lg">
          <Switch label="Is Onboarding"
                  description="State of the account, either is active/disable or onboarding"
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('is_onboarding', { type: 'checkbox' })} />

          <Switch label="Is Active"
                  description="Allows an account to be enabled or disabled completely"
                  onLabel="Yes" offLabel="No"
                  {...form.getInputProps('is_active', { type: 'checkbox' })} />
        </SimpleGrid>
      </Tabs.Panel>
    </Tabs>

    <Card radius="sm" withBorder mt="xl">
      <Card.Section withBorder px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
