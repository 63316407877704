import { IResourceComponentsProps } from "@refinedev/core";
import { IVoiceMessageListResponse } from '@interfaces';
import { VoiceMessageTable } from './table/VoiceMessageTable';
import { useFeatures } from 'flagged';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterSelect } from '@components/filters/FilterSelect';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { EuiCallOut } from '@elastic/eui';
import { Divider, Space, Text } from '@mantine/core';
import { FilterButtonGroup } from '@components/filters/FilterButtonGroup';
import { FilterToggle } from '@components/filters/FilterToggle';
import { VoiceMessageSettingsForm } from './form/VoiceMessageSettingsForm';

export function VoiceMessageList({ initialData }: IResourceComponentsProps<IVoiceMessageListResponse>) {
  const features = useFeatures();

  const options = [
    { label: 'All', value: 'All' },
    { label: 'Unread', value: 'Unread' },
    { label: 'Read', value: 'Read' },
  ];

  const quickFilters = <>
    <FilterButtonGroup name="status" options={options} />
    <Divider orientation="vertical" style={{ height: 40 }} />
    <FilterToggle name="is_archived" label="Archived" />
  </>;

  const filters = features.admin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    <FilterBar filters={filters} quickFilters={quickFilters} />

    { !features.admin && <VoiceMessageSettingsForm /> }

    <EuiCallOut iconType="help" title={
      <Text size="sm" fw="500" span>
        NOTE: messages are automatically transcribed using voice recognition technology. Content and references may contain mistakes and errors.
      </Text>
    }></EuiCallOut>

    <Space h={16} visibleFrom="sm" />

    <VoiceMessageTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
