import {
  Alert,
  Button, Checkbox,
  Divider,
  Grid,
  Group, Input,
  InputWrapper,
  NumberInput,
  SegmentedControl,
  Select,
  Stack, Switch, TextInput, TextInputProps
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useInvalidate } from '@refinedev/core';
import { IPaymentMethod, IPaymentMethodTypes, IRefundMethod, IRefundMethodTypes, IRefundRecord } from '@interfaces';
import { CustomerCard } from '@components/data/cards/CustomerCard';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { useDrawers } from '@components/ui/drawerManager';
import { IconCheck, IconCurrencyDollar, IconExclamationCircle } from '@tabler/icons-react';
import { useDidUpdate } from '@mantine/hooks';
import { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import 'yup-phone-lite';
import { useIdentity } from '@components/data/Identity.context';
import { EMAIL_REGEX } from '@components/ui/EmailRegex';
import { useCheckZelleAlias } from './UseCheckZelleAlias';
import { TagAlert, TagDetailsCard, TagSelector } from '@components/ui/drawers/Requests/TagComponents';
import { useUpdateOrCreate } from '@components/hooks';
import { isNull, isUndefined } from 'lodash';

type Props = {
  paymentMethods: IPaymentMethod[];
  refundMethods: IRefundMethod[];
};

export const RefundRequestForm = ({ paymentMethods, refundMethods }: Props) => {
  const { owner } = useIdentity();
  const { closeAll } = useDrawers();
  const invalidate = useInvalidate();
  const { customer, reset, tag, setType, refund, help, request, setRequest, setRefund } = useRequestState();
  const { mutate, isLoading: isSaving } = useUpdateOrCreate<IRefundRecord>();

  const { data: zelle, checkZelleAlias, isLoading: isCheckingZelle, error: zelleError, reset: resetZelle } = useCheckZelleAlias();

  const [refundMethod, setRefundMethod] = useState<IRefundMethod>(refund?.refund_method);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>(refund?.payment_method);
  const [daysSincePurchase, setDaysSincePurchase] = useState<string | number>(refund?.days_since_payment ? `${refund?.days_since_payment}` : null);
  const [collectDetails, setCollectDetails] = useState<boolean>(false);

  const translation = owner.translations.en;

  const isPreAuthEnabled = paymentMethods?.length > 0 && owner.settings.refunds_active && owner.settings['refunds_has-pre-auth-enabled'];
  const isReversal = paymentMethod?.pre_auth_refund_method_type === 'Reversal';
  const isRefund = paymentMethod?.pre_auth_refund_method_type === 'Refund' || !isPreAuthEnabled;

  const isRefundMethodVendorCardType = (type) => refundMethod?.type === 'VendorCard' && refundMethod?.settings['vendorcard_account-identifier'] === type;
  const isPaymentMethodVendorCardType = (type) => paymentMethod?.type === 'VendorCard' && paymentMethod?.settings['vendorcard_account-identifier'] === type;

  const isRefundMethodType = (type: IRefundMethodTypes) => refundMethod?.type === type;
  const isPaymentMethodType = (type: IPaymentMethodTypes) => paymentMethod?.type === type;

  const isEitherMethodType = (type) => isRefundMethodType(type) || isPaymentMethodType(type);

  const isPaymentMethodPreAuthEnabled = !!paymentMethod?.pre_auth_enabled;
  const hasPreAuthPeriodExceeded = useCallback(() => {
    if (paymentMethod && isPaymentMethodPreAuthEnabled) {
      if (daysSincePurchase) {
        const past = moment().subtract(daysSincePurchase, 'day').startOf('day');

        return Math.abs(past.diff(moment(), 'hours')) >= paymentMethod.pre_auth_follow_up_delay;
      }

      return false;
    }

    return true;
  }, [paymentMethod, daysSincePurchase]);

  const needsManualDetails = isPaymentMethodPreAuthEnabled && !!daysSincePurchase && collectDetails;
  const needsAllDetails = paymentMethod?.settings?.['collect_all_refund-details'];

  const needsRefundDetails = (isRefund || isReversal && needsAllDetails) && (hasPreAuthPeriodExceeded() || needsManualDetails);
  const needsReversalDetails = (isReversal || isRefund && needsAllDetails) && (hasPreAuthPeriodExceeded() || needsManualDetails);

  const refundMaxAmount = owner.settings['refunds_max-amount'] || 20;
  const vendorCardMaxLength = (isReversal ? paymentMethod : refundMethod)?.settings?.vendorcard_maxlength ?? 16;

  const schema = Yup.object().shape({
    amount: Yup.number()
      .transform(val => val || null)
      .nullable()
      .min(0.01, 'Please enter an amount more than $0.01')
      .max(refundMaxAmount, `Please enter an amount less than $${refundMaxAmount}`)
      .required('Please provide an amount'),
    payment_method_id: Yup.string().nullable().when({
      is: () => isPreAuthEnabled,
      then: (schema) => schema.required('Select a payment method'),
    }),
    refund_method_id: Yup.string().nullable().when({
      is: () => needsRefundDetails,
      then: (schema) => schema.required('Select a refund method'),
    }),

    refund_days_since_purchase: Yup.string().nullable().when({
      is: () => isPaymentMethodPreAuthEnabled,
      then: (schema) => schema.required('Select a value'),
    }),

    oan_card_number: Yup.string().when({
      is: () => (
        (isRefundMethodType('VendorCard') && isRefundMethodVendorCardType('CardNumber')) ||
        (needsReversalDetails && isPaymentMethodType('VendorCard') && isPaymentMethodVendorCardType('CardNumber'))
      ),
      then: (schema) => schema.required('Enter a value').matches(new RegExp(`^.{${vendorCardMaxLength}}$`), 'Enter a valid card number')
    }),
    refund_email: Yup.string().when(['interac_contact_method', 'zelle_enrollment_method'], {
      is: (contactMethod, enrollmentMethod) => (
        (isEitherMethodType('Interac') && contactMethod === 'email') ||
        (isEitherMethodType('Zelle') && enrollmentMethod === 'email') ||
        (isRefundMethodType('VendorCard') && isRefundMethodVendorCardType('Email')) ||
        (needsReversalDetails && isPaymentMethodType('VendorCard') && isPaymentMethodVendorCardType('Email')) ||
        isEitherMethodType('Paypal')
      ),
      then: (schema) => schema.required('Enter a value').matches(EMAIL_REGEX, 'Enter a valid email'),
    }),
    refund_phone: Yup.string().when(['interac_contact_method', 'zelle_enrollment_method'], {
      is: (contactMethod, enrollmentMethod) => (
        (isEitherMethodType('Interac') && contactMethod === 'mobile') ||
        (isEitherMethodType('Zelle') && enrollmentMethod === 'mobile') ||
        (isRefundMethodType('VendorCard') && isRefundMethodVendorCardType('PhoneNumber')) ||
        (needsReversalDetails && isPaymentMethodType('VendorCard') && isPaymentMethodVendorCardType('PhoneNumber')) ||
        isEitherMethodType('Venmo')
      ),
      then: (schema) => schema.required('Enter a value').phone(['US', 'CA'], 'Enter a valid mobile'),
    }),

    first_six_digits: Yup.string().when({
      is: () => needsReversalDetails && isPaymentMethodType('Card'),
      then: (schema) => schema
        .test('len', 'Enter the first 6 digits', val => `${val}`.length === 6)
        .matches(/^[0-9]+$/, 'Enter numbers only')
        .required('Enter the first 6 digits'),
    }),

    last_four_digits: Yup.string().when({
      is: () => (
        needsReversalDetails && (
          isPaymentMethodType('ApplePay') ||
          isPaymentMethodType('AppleCard') ||
          isPaymentMethodType('GooglePay') ||
          isPaymentMethodType('Card')
        )
      ),
      then: (schema) => schema
        .test('len', 'Enter the last 4 digits', val => `${val}`.length === 4)
        .matches(/^[0-9]+$/, 'Enter numbers only')
        .required('Enter the last 4 digits'),
    }),

    interac_contact_method: Yup.string(),
    interac_question: Yup.string().when({
      is: () => isRefundMethodType('Interac'),
      then: (schema) => schema.required('Enter a value'),
    }),
    interac_answer: Yup.string().when({
      is: () => isRefundMethodType('Interac'),
      then: (schema) => schema.required('Enter a value')
        .min(3, 'Enter at least 3 characters')
        .matches(/^[a-zA-ZÀ-ž0-9]*$/, 'Only letter or numbers allowed'),
    }),

    refund_name: Yup.string().when({
      is: () => isRefundMethodType('Cash') || isRefundMethodType('Check'),
      then: (schema) => schema.required('Enter a value'),
    }),
    refund_country:  Yup.string(),
    refund_address_line_1: Yup.string().when({
      is: () => isRefundMethodType('Cash') || isRefundMethodType('Check'),
      then: (schema) => schema.required('Enter a value'),
    }),
    refund_address_line_2: Yup.string().optional(),
    refund_city: Yup.string().when({
      is: () => isRefundMethodType('Cash') || isRefundMethodType('Check'),
      then: (schema) => schema.required('Enter a value'),
    }),
    refund_state: Yup.string().when({
      is: () => isRefundMethodType('Cash') || isRefundMethodType('Check'),
      then: (schema) => schema.required('Enter a value'),
    }),
    refund_zip_code: Yup.string().when({
      is: () => isRefundMethodType('Cash') || isRefundMethodType('Check'),
      then: (schema) => schema.required('Enter a value'),
    }),

    refund_first_name: Yup.string().when({
      is: () => isRefundMethodType('Zelle'),
      then: (schema) => schema.required('Enter a value'),
    }),
    refund_last_name: Yup.string().when({
      is: () => isRefundMethodType('Zelle'),
      then: (schema) => schema.required('Enter a value'),
    }),
  });

  const defaultValues = {
    refund_days_since_purchase: null,
    follow_up_instant: false,
  };

  const defaultReversalValues = {
    first_six_digits: '',
    last_four_digits: '',
  };

  const defaultRefundValues = {
    refund_email: '',
    refund_phone: '',
    refund_first_name: '',
    refund_last_name: '',
    refund_name: '',
    refund_country: owner.country_code,
    refund_address_line_1: '',
    refund_address_line_2: '',
    refund_city: '',
    refund_state: '',
    refund_zip_code: '',
    oan_card_number: '',
    zelle_enrollment_method: 'mobile',
    interac_contact_method: 'mobile',
    interac_answer: '',
    interac_question: '',
  };

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      customer_id: customer.id,
      amount: refund?.amount,
      refund_method_id: refund?.refund_method?.id ? `${refund?.refund_method?.id}` : null,
      payment_method_id: refund?.payment_method?.id ? `${refund?.payment_method?.id}` : null,
      next_action: false,
      next_action_type: 'help',
      refund_days_since_purchase: !isUndefined(refund?.days_since_payment) && !isNull(refund?.days_since_payment) ? `${refund?.days_since_payment}` : null,
      first_six_digits: refund?.payment_method_attributes?.first_six_digits ?? '',
      last_four_digits: refund?.payment_method_attributes?.last_four_digits ?? '',
      refund_email: refund?.payout?.email ?? '',
      refund_phone: refund?.payout?.phone_number ?? '',
      refund_first_name: refund?.payout?.first_name ?? '',
      refund_last_name: refund?.payout?.last_name ?? '',
      refund_name: refund?.address?.name ?? '',
      refund_country: owner.country_code,
      refund_address_line_1: refund?.address?.address_line_1 ?? '',
      refund_address_line_2: refund?.address?.address_line_2 ?? '',
      refund_city: refund?.address?.city ?? '',
      refund_state: refund?.address?.state ?? '',
      refund_zip_code: refund?.address?.zip_code ?? '',
      oan_card_number: refund?.payout?.oan_card_number ?? '',
      zelle_enrollment_method: refund?.payout?.phone_number ? 'mobile' : 'email',
      interac_contact_method: refund?.payout?.phone_number ? 'mobile' : 'email',
      interac_answer: refund?.payout?.security_answer ?? '',
      interac_question: refund?.payout?.security_question ?? '',
    }
  });

  const handleSubmit = (values) => {
    values.request_id = request?.id ?? null;
    values.tag_id = tag?.id ?? null;
    values.collect_details = collectDetails;
    values.amount = Number(values.amount).toFixed(2);

    mutate({ resource: 'refund', id: refund?.id, values }, {
      onSuccess: async ({ data }) => {
        if (values.next_action) {
          setRefund(data);
          setRequest(data.request);
          setType(values.next_action_type);
        } else {
          closeAll();
        }

        await invalidate({ resource: 'refund', invalidates: ['all'] });
        await invalidate({ resource: 'help', invalidates: ['all'] });
        await invalidate({ resource: 'voice_message', invalidates: ['all'] });
      }
    });
  };

  const triggerCheckAlias = async () => {
    const phoneResults = form.validateField('refund_phone');
    const emailResults = form.validateField('refund_email');

    if (phoneResults.hasError || emailResults.hasError) {
      return;
    }

    const values = form.values;
    const alias = values.zelle_enrollment_method === 'mobile' ? values.refund_phone : values.refund_email;

    await checkZelleAlias(values.zelle_enrollment_method, alias);
  };

  const resetValues = (values) => Object.entries(values).forEach(([f, v]) => form.setFieldValue(f, v));

  useDidUpdate(() => {
    setRefundMethod(refundMethods.find(v => v.id == form.values.refund_method_id) ?? null);
  }, [form.values.refund_method_id]);

  useDidUpdate(() => {
    setDaysSincePurchase(null);
    setCollectDetails(false);
    resetValues(defaultValues);
    resetValues(defaultReversalValues);
    resetValues(defaultRefundValues);
    resetZelle();
  }, [paymentMethod]);

  useDidUpdate(() => {
    resetValues(defaultRefundValues);
    resetZelle();
  }, [refundMethod]);

  useDidUpdate(() => {
    resetValues(defaultRefundValues);
    form.setFieldValue('follow_up_instant', false);
  }, [collectDetails]);

  useDidUpdate(() => {
    setPaymentMethod(paymentMethods.find(v => v.id == form.values.payment_method_id) ?? null);
    form.setFieldValue('refund_method_id', null);
  }, [form.values.payment_method_id]);

  useDidUpdate(() => {
    form.setFieldValue('refund_method_id', null);
    form.setFieldValue('follow_up_instant', false);
  }, [daysSincePurchase]);

  useDidUpdate(() => {
    setDaysSincePurchase(form.values.refund_days_since_purchase);
    setCollectDetails(false);
  }, [form.values.refund_days_since_purchase]);

  useDidUpdate(() => {
    if (zelle?.data.first_name && zelle?.data?.last_name) {
      form.setFieldValue('refund_first_name', zelle?.data?.first_name);
      form.setFieldValue('refund_last_name', zelle?.data?.last_name);

      form.validate();
    }
  }, [zelle]);

  useDidUpdate(() => {
    if (zelle?.data && refundMethod.type === 'Zelle') {
      resetZelle();
    }
  }, [form.values.refund_email, form.values.refund_phone]);

  useDidUpdate(() => {
    resetValues({'refund_email': '', 'refund_phone': '', 'interac_answer': '', 'interac_question': ''});
    resetZelle();
  }, [form.values.zelle_enrollment_method, form.values.interac_contact_method]);

  const RefundEmailField = (label: string, props?: TextInputProps) => {
    return <TextInput label={label} placeholder="their@email.com" maxLength={50} {...form.getInputProps('refund_email')} {...props} />;
  };

  const RefundPhoneField = (label: string, props?: TextInputProps) => {
    return <TextInput type="tel" label={label} placeholder="Enter mobile number" maxLength={50} {...form.getInputProps('refund_phone')} {...props} />;
  };

  const hasZelleError = !!zelleError || zelle?.data && zelle?.data.is_enrolled && !zelle?.data.first_name && !zelle?.data.last_name;

  return <>
    <form onSubmit={form.onSubmit(handleSubmit)} autoComplete="off">
      <Grid gutter="xs">
        <Grid.Col span={{ lg: 6 }}>
          <Grid gutter="xs">
            <Grid.Col span={{ lg: 12 }}>
              <Stack>
                <TagSelector />
                <TagAlert />
              </Stack>
            </Grid.Col>

            <Grid.Col span={{ lg: 6 }}>
              <NumberInput leftSection={<IconCurrencyDollar color="black" />}
                           label="Refund amount"
                           description="The amount requesting to be refunded."
                           decimalScale={2}
                           fixedDecimalScale
                           step={.05}
                           hideControls
                          { ...form.getInputProps('amount') }
              />
            </Grid.Col>

            { isPreAuthEnabled && <Grid.Col span={{ lg: 6 }}>
              <Select label="Payment method"
                      data={paymentMethods.map((v) => ({ value: `${v.id}`, label: v.name ?? v.type })) || []}
                      {...form.getInputProps('payment_method_id')}
              />
            </Grid.Col> }

            { isPaymentMethodPreAuthEnabled && <Grid.Col span={{ lg: 12 }}>
              <Select label="When"
                      description="On what day was purchase?"
                      data={[
                        { value: '0', label: 'Today' },
                        { value: '1', label: 'Yesterday' },
                        { value: '2', label: 'Two days ago' },
                        { value: '3', label: 'More than two days ago' },
                      ]}
                      {...form.getInputProps('refund_days_since_purchase')}
              />
            </Grid.Col>}

            { isPaymentMethodPreAuthEnabled && !!daysSincePurchase && <>
              { !hasPreAuthPeriodExceeded() && <Grid.Col span={{ lg: 12 }}>
                <Switch label="Enter refund details"
                        description={ collectDetails ? `You will complete the applicable refund details.` : `The customer will be sent a notification to provide refund details.`}
                        offLabel="No" onLabel="Yes"
                        checked={collectDetails}
                        onChange={(event) => setCollectDetails(event.currentTarget.checked)}
                />
              </Grid.Col>}

              { !hasPreAuthPeriodExceeded() && !collectDetails && <Grid.Col span={{ lg: 12 }}>
                <Switch label="Send Pre-Authorization Follow up"
                        description={form.values.follow_up_instant ? `The pre-authorization delay will be skipped and the customer will immediately receive a notification to provide refund details.` : `The notification for the customer to provide refund details will be sent after the pre-authorization time has elapsed.`}
                        offLabel="No" onLabel="Yes"
                        {...form.getInputProps('follow_up_instant', { type: 'checkbox' })}
                />
              </Grid.Col>}
            </>}

            { needsReversalDetails && <>
              { isPaymentMethodType('ApplePay') && <Grid.Col span={{ lg: 12 }}>
                <TextInput label={translation.steps.refund_apple_pay_details.title}
                           description={translation.fields.last_four_digits.apple_pay_aside}
                           maxLength={4}
                           {...form.getInputProps('last_four_digits')} />
              </Grid.Col>}

              { isPaymentMethodType('AppleCard') && <Grid.Col span={{ lg: 12 }}>
                <TextInput label={translation.steps.refund_apple_card_details.title}
                           description={translation.fields.last_four_digits.apple_card_aside}
                           maxLength={4}
                           {...form.getInputProps('last_four_digits')} />
              </Grid.Col>}

              { isPaymentMethodType('GooglePay') && <Grid.Col span={{ lg: 12 }}>
                <TextInput label={translation.steps.refund_google_pay_details.title}
                           description={translation.fields.last_four_digits.google_pay_aside}
                           maxLength={4}
                           {...form.getInputProps('last_four_digits')} />
              </Grid.Col>}

              { isPaymentMethodType('Card') && <Grid.Col span={{ lg: 12 }}>
                <Input.Label>Card details</Input.Label>

                <Group grow gap="xs">
                  <TextInput description="First 6 numbers" maxLength={6} {...form.getInputProps('first_six_digits')} />
                  <TextInput description="Last 4 numbers" maxLength={4} {...form.getInputProps('last_four_digits')} />
                </Group>
              </Grid.Col>}
            </>}

            { needsReversalDetails && isPaymentMethodType('VendorCard') && <Grid.Col span={{ lg: 12 }}>
              { isPaymentMethodVendorCardType('CardNumber') && <TextInput label="Card number" {...form.getInputProps('oan_card_number')} />}
              { isPaymentMethodVendorCardType('Email') && RefundEmailField('Email')}
              { isPaymentMethodVendorCardType('PhoneNumber') && RefundPhoneField('Mobile No.')}
            </Grid.Col>}

            { needsRefundDetails && <>
              <Grid.Col span={{ lg: isPreAuthEnabled ? 12 : 6 }}>
                <Select label="Refund method"
                        data={refundMethods.map((v) => ({ value: `${v.id}`, label: v.name ?? v.type })) || []}
                        {...form.getInputProps('refund_method_id')}
                />
              </Grid.Col>

              { isRefundMethodType('Paypal') && <Grid.Col span={{ lg: 12 }}>
                { RefundEmailField('PayPal email') }
              </Grid.Col>}

              { isRefundMethodType('Venmo') && <Grid.Col span={{ lg: 12 }}>
                { RefundPhoneField('Venmo Mobile No.') }
              </Grid.Col>}

              { (isRefundMethodType('Cash') || isRefundMethodType('Check')) && <Grid.Col span={{ lg: 12 }}>
                <Grid gutter="xs">
                  <Grid.Col span={{ lg: 12 }}>
                    <TextInput label="Payment to" maxLength={75} {...form.getInputProps('refund_name')} />
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <Select label="Country"
                            data={[
                              { value: 'US', label: 'United States' },
                              { value: 'CA', label: 'Canada' },
                            ]}
                            {...form.getInputProps('refund_country')} />
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <TextInput label="Street Address" maxLength={50} {...form.getInputProps('refund_address_line_1')} />
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <TextInput label="Building / Suite" maxLength={50} {...form.getInputProps('refund_address_line_2')} />
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <TextInput label="City" maxLength={50} {...form.getInputProps('refund_city')} />
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <TextInput label={ form.values.refund_country === 'CA' ? 'Province' : 'State' }
                               maxLength={50} {...form.getInputProps('refund_state')} />
                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <TextInput label={ form.values.refund_country === 'CA' ? 'Postal Code' : 'Zip Code' }
                               maxLength={50} {...form.getInputProps('refund_zip_code')} />
                  </Grid.Col>
                </Grid>
              </Grid.Col>}

              { isRefundMethodType('VendorCard') && <Grid.Col span={{ lg: 12 }}>
                { isRefundMethodVendorCardType('CardNumber') && <TextInput label="Card number" {...form.getInputProps('oan_card_number')} />}
                { isRefundMethodVendorCardType('Email') && RefundEmailField('Email')}
                { isRefundMethodVendorCardType('PhoneNumber') && RefundPhoneField('Mobile No.')}
              </Grid.Col>}

              { isRefundMethodType('Zelle') && <>
                <Grid.Col span={{ lg: 6 }}>
                  <InputWrapper label="Zelle account details"
                                description="What is their Zelle account linked to?">
                    <SegmentedControl fullWidth mb={5}
                                      data={[
                                        { value: 'mobile', label: 'Mobile no.' },
                                        { value: 'email', label: 'Email' },
                                      ]}
                                      {...form.getInputProps('zelle_enrollment_method')}
                    />
                  </InputWrapper>
                </Grid.Col>

                { form.values.zelle_enrollment_method === 'email' && <>
                  <Grid.Col span={{ lg: 12 }}>
                    <Group wrap="nowrap" gap="xs" align="start">
                      { RefundEmailField('Zelle email', {
                        onBlur: triggerCheckAlias,
                        style: { flex: 1 }
                      })}
                      <Button variant="light" size="sm" mt="30" loading={isCheckingZelle} onClick={triggerCheckAlias}>Check</Button>
                    </Group>
                  </Grid.Col>
                </>}

                { form.values.zelle_enrollment_method === 'mobile' && <>
                  <Grid.Col span={{ lg: 12 }}>
                    <Group wrap="nowrap" gap="xs" align="start">
                      { RefundPhoneField('Zelle mobile no.', {
                        onBlur: triggerCheckAlias,
                        style: { flex: 1 }
                      })}
                      <Button variant="light" size="sm" mt="30" loading={isCheckingZelle} onClick={triggerCheckAlias}>Check</Button>
                    </Group>
                  </Grid.Col>
                </>}

                { zelle?.data.first_name && zelle?.data.last_name && <>
                  <Grid.Col span={{ lg: 12 }}>
                    <Alert variant="light" color="green" icon={<IconCheck />}>
                      { translation.steps.refund_zelle.enrolled_message
                        .replace(':first_name', zelle?.data.first_name)
                        .replace(':last_name', zelle?.data.last_name)}
                    </Alert>
                  </Grid.Col>
                </>}

                { zelle?.data.is_enrolled && zelle?.data.first_name && !zelle?.data.last_name && <>
                  <Grid.Col span={{ lg: 12 }}>
                    <Alert variant="light" color="orange" icon={<IconExclamationCircle />}>
                      { translation.steps.refund_zelle.non_individual_message }
                    </Alert>
                  </Grid.Col>
                </>}

                { zelle?.data && !zelle?.data.is_enrolled && <>
                  <Grid.Col span={{ lg: 12 }}>
                    <Alert variant="light" color="orange" icon={<IconExclamationCircle />}>
                      { translation.steps.refund_zelle.not_enrolled_message.replace(':alias', zelle?.data.alias) }
                    </Alert>
                  </Grid.Col>
                </>}

                { hasZelleError && <>
                  <Grid.Col span={{ lg: 12 }}>
                    <Alert variant="light" color="red" icon={<IconExclamationCircle />}>
                      { translation.steps.refund_zelle.failed_message }
                    </Alert>
                  </Grid.Col>
                </>}
              </>}

              { isRefundMethodType('Interac') && <>
                <Grid.Col span={{ lg: 6 }}>
                  <InputWrapper label="Interac account details"
                                description="What is their account linked to?">
                    <SegmentedControl fullWidth mb={5}
                                      data={[
                                        { value: 'mobile', label: 'Mobile no.' },
                                        { value: 'email', label: 'Email' },
                                      ]}
                                      {...form.getInputProps('interac_contact_method')}
                    />
                  </InputWrapper>
                </Grid.Col>

                { form.values.interac_contact_method === 'email' && <>
                  <Grid.Col span={{ lg: 6 }}>
                    { RefundEmailField('Interac email') }
                  </Grid.Col>
                </>}

                { form.values.interac_contact_method === 'mobile' && <>
                  <Grid.Col span={{ lg: 6 }}>
                    { RefundPhoneField('Interac mobile no.') }
                  </Grid.Col>
                </>}

                <Grid.Col span={{ lg: 6 }}>
                  <Select label="Interac security question"
                          description="Select a security question to use"
                          data={[
                            { value: 'Your vehicle', label: 'Your vehicle' },
                            { value: 'Favorite color', label: 'Favorite color' },
                            { value: 'Middle name', label: 'Middle name' },
                            { value: 'Pet name', label: 'Pet name' },
                          ]}
                          {...form.getInputProps('interac_question')}
                />
                </Grid.Col>

                <Grid.Col span={{ lg: 6 }}><TextInput label="Interac security answer" {...form.getInputProps('interac_answer')} /></Grid.Col>
              </>}
            </>}
          </Grid>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <Stack mt="sm">
            <CustomerCard customer={customer} />
            <TagDetailsCard />
          </Stack>
        </Grid.Col>
      </Grid>

      <Divider my="md" />

      { !help && <>
        <Group justify="right" mb="md">
          <Select leftSection={<Checkbox {...form.getInputProps('next_action', { type: 'checkbox' })} />}
                  data={[
                    { value: 'help', label: 'Create help request' },
                    ...(['development', 'staging'].includes(process.env.NEXT_PUBLIC_ENV) ? [{ value: 'link', label: 'Send help link' }] : [])
                  ]}
                  {...form.getInputProps('next_action_type')}
          />
        </Group>
      </>}

      <Group wrap="nowrap" gap="xs" justify="right">
        <Button variant="light" color="gray.8" onClick={() => refund ? closeAll() : reset()}>Cancel</Button>
        <Button variant="filled" type="submit" loading={isSaving} disabled={isCheckingZelle || hasZelleError || zelle?.data && !zelle?.data.is_enrolled}>
          { refund?.id ? 'Update' : 'Save' }
        </Button>
      </Group>
    </form>
  </>;
};
