import { HttpError, useMany } from "@refinedev/core";
import { IFilterOptionItem } from '@interfaces';
import type { UseManyProps } from "@refinedev/core/dist/hooks/data/useMany";

type Props = Partial<{
  filters: {
    active?: boolean;
    owner_id?: number;
    type?: 'VMS';
  };
} & Partial<UseManyProps<IFilterOptionItem, HttpError, IFilterOptionItem>>>;

export const useIntegrationOptions = (props?: Props) => {
  let url = 'filter/options/integrations';

  if (props.filters) {
    url += '?' + new URLSearchParams(props.filters as Record<string, any>).toString()
  }

  return useMany({ resource: url, ids: [], ...props });
};
