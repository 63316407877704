import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { ICustomerListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { CustomerTable } from './table/CustomerTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { FilterSingleRange } from '@components/filters/FilterSingleRange';
import { Feature } from 'flagged';
import { FilterSelect } from '@components/filters/FilterSelect';
import { Button } from '@mantine/core';

export function CustomerList({ initialData }: IResourceComponentsProps<ICustomerListResponse>) {
  const { list } = useNavigation();

  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterSingleRange name="help_count" label="Help" min={0} max={100} />
    <FilterSingleRange name="feedback_count" label="Feedback" min={0} max={100} />
    <Feature name="refunds">
      <FilterSingleRange name="refunds_count" label="Refunds" min={0} max={100} />
    </Feature>
    <Feature name="voice_messages">
      <FilterSingleRange name="voice_count" label="Calls" min={0} max={200} />
    </Feature>
  </>;

  const actions = ['development', 'staging'].includes(process.env.NEXT_PUBLIC_ENV)  ? <>
    <Button onClick={() => list('request_links')}>
      View request links
    </Button>
  </> : null;

  return <TableFilterProvider>
    <FilterBar filters={filters} actions={actions} />
    <CustomerTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
