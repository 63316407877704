import { Badge, Grid, Group, Loader, Text } from '@mantine/core';
import {
  ITagListItem,
  ITagRecord,
  IFeedbackListItem,
  IHelpListItem,
  IRefundListItem,
  ICustomerListItem, IVoiceMessageListItem
} from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { LogicalFilter, useList } from '@refinedev/core';
import { HelpTable } from '../../help/table/HelpTable';
import { TableFilterContext, useTableFilters } from '@components/table/TableFilter.context';
import { FeedbackTable } from '../../feedback/table/FeedbackTable';
import { RefundTable } from '../../refund/table/RefundTable';
import { CustomerTable } from '../../customer/table/CustomerTable';
import { Tabs } from '@components/ui/tabs/Tabs';
import { Feature, useFeature } from 'flagged';
import { EquipmentCard } from '@components/data/cards/EquipmentCard';
import { VoiceMessageTable } from '../../voice_message/table/VoiceMessageTable';

type Props = {
  item: ITagListItem;
}

type TabContentProps = {
  record: ITagRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const { filters } = useTableFilters();
  const hasRefunds = useFeature('refunds');
  const hasVoiceMessages = useFeature('voice_messages');

  const voiceMessageFilters = [{ field: 'is_archived', value: '*' }, ...filters]  as LogicalFilter[];

  const { data: helpResults, isLoading: isHelpLoading } = useList<IHelpListItem>({
    resource: 'help',
    filters
  });
  const { data: feedbackResults, isLoading: isFeedbackLoading } = useList<IFeedbackListItem>({
    resource: 'feedback',
    filters
  });
  const { data: customerResults, isLoading: isCustomerLoading } = useList<ICustomerListItem>({
    resource: 'customer',
    filters
  });
  const { data: refundResults, isLoading: isRefundLoading } = useList<IRefundListItem>({
    resource: 'refund',
    queryOptions: { enabled: !!hasRefunds },
    filters
  });
  const { data: voiceResults, isLoading: isVoiceLoading } = useList<IVoiceMessageListItem>({
    resource: 'voice_message',
    queryOptions: { enabled: !!hasVoiceMessages },
    filters: voiceMessageFilters,
  });

  return <Tabs variant="outline" defaultValue="details">
    <Tabs.List>
      <Tabs.Tab value="details">
        <Text size="sm" fw="500">Details</Text>
      </Tabs.Tab>
      <Tabs.Tab value="help">
        <Group gap="xs">
          <Text size="sm" fw="500">Help</Text>
          { isHelpLoading ? <Loader size={16} /> : <Badge>{helpResults.total}</Badge>}
        </Group>
      </Tabs.Tab>
      <Feature name="voice_messages">
        <Tabs.Tab value="voice_messages">
          <Group gap="xs">
            <Text size="sm" fw="500">Calls</Text>
            { isVoiceLoading ? <Loader size={16} /> : <Badge>{voiceResults.total}</Badge>}
          </Group>
        </Tabs.Tab>
      </Feature>
      <Tabs.Tab value="feedback">
        <Group gap="xs">
          <Text size="sm" fw="500">Feedback</Text>
          { isFeedbackLoading ? <Loader size={16} /> : <Badge>{feedbackResults.total}</Badge>}
        </Group>
      </Tabs.Tab>
      <Feature name="refunds">
        <Tabs.Tab value="refunds">
          <Group gap="xs">
            <Text size="sm" fw="500">Refunds</Text>
            { isRefundLoading ? <Loader size={16} /> : <Badge>{refundResults.total}</Badge>}
          </Group>
        </Tabs.Tab>
      </Feature>
      <Tabs.Tab value="customers">
        <Group gap="xs">
          <Text size="sm" fw="500">Customers</Text>
          { isCustomerLoading ? <Loader size={16} /> : <Badge>{customerResults.total}</Badge>}
        </Group>
      </Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="details" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <EquipmentCard tag={record} />
        </Grid.Col>
      </Grid>
    </Tabs.Panel>

    <Tabs.Panel value="help" pt="xs">
      { helpResults && <HelpTable initialData={helpResults} excludeColumns={['owner_name', 'id']} /> }
    </Tabs.Panel>

    <Tabs.Panel value="feedback" pt="xs">
      { feedbackResults && <FeedbackTable initialData={feedbackResults} excludeColumns={['owner_name', 'id']} /> }
    </Tabs.Panel>

    <Feature name="refunds">
      <Tabs.Panel value="refunds" pt="xs">
        { refundResults && <RefundTable initialData={refundResults} excludeColumns={['owner_name', 'id']} /> }
      </Tabs.Panel>
    </Feature>

    <Feature name="voice_messages">
      <Tabs.Panel value="voice_messages" pt="xs">
        { voiceResults && <TableFilterContext.Provider value={{ filters: voiceMessageFilters, excludeDefaultFilters: true }}>
          <VoiceMessageTable initialData={voiceResults} excludeColumns={['owner_name']} />
        </TableFilterContext.Provider>}
      </Tabs.Panel>
    </Feature>

    <Tabs.Panel value="customers" pt="xs">
      { customerResults && <CustomerTable initialData={customerResults} excludeColumns={['owner_name']} /> }
    </Tabs.Panel>
  </Tabs>;
}

export default ({ item }: Props) => {
  return <TableRow<any> resource="tag" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
