import { useState } from 'react';
import { IResourceComponentsProps, useApiUrl, useCustomMutation } from "@refinedev/core";
import { IWebhookResponse } from '@interfaces';
import {
  Button,
  Card,
  Divider,
  Group,
  Input,
  Select,
  Stack,
  Switch,
  TextInput,
  ActionIcon,
  Loader,
  Tabs,
  Text,
  Code,
  Title,
  Alert
} from '@mantine/core';
import { IconAlertCircle, IconTrash } from '@tabler/icons-react';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { useIdentity } from '@components/data/Identity.context';
import { Feature } from 'flagged';
import { useOwnerOptions } from '@components/hooks';
import { ResourceForm } from '@components/ui/form/ResourceForm';

const schema = Yup.object().shape({
  roles: Yup.array(),
  owner_id: Yup.mixed().nullable().when('roles', {
    is: (roles) => roles.includes('Admin'),
    then: (schema) => schema.required('Assign an owner')
  }),
  name: Yup.string().required('Enter a name'),
  url: Yup.string().url('Enter a valid url').required('Enter a url'),
  method: Yup.string().required('Select a method'),
  event_type: Yup.string().required('Select an event type'),
  version: Yup.number().required('Select a version'),
  headers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().max(50).required('Enter a name'),
      value: Yup.string().max(100).required('Text is required'),
    })
  ),
  status: Yup.boolean()
});

export function WebhookEdit({ initialData: record }: IResourceComponentsProps<IWebhookResponse>) {
  const apiUrl = useApiUrl();
  const { identity } = useIdentity();
  const [activeTab, setActiveTab] = useState<string | null>('details');
  const [previewData, setPreviewData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const { mutate: send, isLoading: isSending } = useCustomMutation();

  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      roles: identity.roles,
      owner_id: record?.data.owner_id.toString() ?? null,
      name: record?.data.name ?? '',
      url: record?.data.url ?? '',
      method: record?.data.method ?? 'POST',
      event_type: record?.data.event_type ?? '',
      version: String(record?.data.version ?? 1),
      headers: record?.data.headers ?? [],
      enabled: (record?.data.status ?? 'Active') === 'Active',
    },
  });

  const handleSendPreview = () => {
    setPreviewData(null);
    setHasError(false);

    send({
      url: `${apiUrl}/webhook/preview`, method: 'post', values: form.values,
      successNotification: { type: 'success', message: 'Successfully sent preview.' },
      errorNotification: { type: 'error', message: 'Failed when sending preview' },
    }, {
      onSuccess: ({ data }) => {
        setPreviewData(data);
        setHasError(false);
      },
      onError: ({ response }) => {
        setPreviewData(response.data);
        setHasError(true);
      },
    });
  };

  const onAfterSave = () => {
    setActiveTab('details');
  }

  return <ResourceForm form={form} onAfterSave={onAfterSave}>
    <Group mb="lg">
      <Title order={2}>Webhook</Title>
    </Group>

    <Tabs variant="outline" value={activeTab} onChange={setActiveTab} mt="xl">
      <Tabs.List>
        <Tabs.Tab value="details">Details</Tabs.Tab>
        <Tabs.Tab value="preview" disabled={!form.isValid()}>Preview</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="details" pt="xs">
        <Card shadow="sm" radius="sm">
          <Card.Section withBorder p="md">
            <Stack>
              <Feature name="admin">
                <Select
                  label="Assign to owner"
                  placeholder="Select an owner..."
                  searchable
                  leftSection={isOwnersLoading && <Loader size="xs" />}
                  data={owners?.data || []}
                  { ...form.getInputProps('owner_id') }
                />
              </Feature>

              <TextInput label="Name" placeholder="Enter a name..." { ...form.getInputProps('name') } />

              <TextInput label="URL" placeholder="Enter a url..." { ...form.getInputProps('url') } />

              <Group align="start" grow>
                <Select
                  label="Method"
                  placeholder="Select a method..."
                  data={[
                    {value: 'GET', label: 'GET'},
                    {value: 'POST', label: 'POST'},
                    {value: 'PUT', label: 'PUT'},
                  ]}
                  { ...form.getInputProps('method') }
                />

                <Select
                  label="Event type"
                  placeholder="Select an event..."
                  data={[
                    {value: 'help_request.created', label: 'Help request created'},
                    {value: 'help_request.updated', label: 'Help request updated'},
                    {value: 'feedback_request.created', label: 'Feedback request created'},
                    {value: 'feedback_request.updated', label: 'Feedback request updated'},
                    {value: 'refund_request.created', label: 'Refund request created'},
                    {value: 'refund_request.updated', label: 'Refund request updated'},
                  ]}
                  { ...form.getInputProps('event_type') }
                />

                <Select
                  label="Version"
                  placeholder="Select a method..."
                  data={[
                    {value: '1', label: '1'},
                  ]}
                  { ...form.getInputProps('version') }
                />

              </Group>
            </Stack>

            <Divider my="md" />

            <Input.Label mb={0}>Request headers</Input.Label>
            <Input.Description mb="xs">Add any additional request headers</Input.Description>
            {
              form.values.headers.map((_, index) => (
                <Group key={index} mb="xs" gap="xs" align="center">
                  <TextInput
                    placeholder="name"
                    maxLength={50}
                    {...form.getInputProps(`headers.${index}.name`)}
                  />
                  <TextInput
                    placeholder="value"
                    maxLength={100}
                    {...form.getInputProps(`headers.${index}.value`)}
                  />
                  <ActionIcon color="red"
                              variant="light"
                              onClick={() => form.removeListItem('headers', index)}>
                    <IconTrash size={16} />
                  </ActionIcon>
                </Group>
              ))
            }

            <Button size="sm" variant="outline" onClick={() => form.insertListItem('headers', { name: '', value: '' })}>
              Add header
            </Button>

            <Divider my="md" />

            <Switch label="Enabled"
                    description="Marks the webhook as Active or Inactive"
                    offLabel="No" onLabel="Yes"
                    { ...form.getInputProps('enabled', { type: 'checkbox' }) }
            />
          </Card.Section>

          <Card.Section px="md" py="sm">
            <Group justify="right">
              <ResourceForm.CancelButton />
              <Button variant="light"
                      onClick={() => {
                        form.validate();
                        form.isValid() && setActiveTab('preview');
                      }}
              >Preview</Button>
            </Group>
          </Card.Section>
        </Card>
      </Tabs.Panel>

      <Tabs.Panel value="preview" pt="xs">
        <Card shadow="sm" radius="sm">
          <Card.Section withBorder p="md">
            <Stack align="start">
              <Text size="sm">
                Test that your webhook works and see what payload is sent. It will use one of your latest requests as the payload depending on selected event type.
              </Text>

              <Button variant="outline"
                      loading={isSending}
                      onClick={handleSendPreview}
              >Send preview</Button>

              { previewData?.status === false && <>
                <Alert icon={<IconAlertCircle size="1rem" />} title="Error" variant="filled" color="red" w="100%">
                  <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: 13 }}>
                    {JSON.stringify(previewData.received, null, 2)}
                  </pre>
                </Alert>
              </>}

              { previewData?.sent && <>
                <Code style={{ width: '100%', overflowY: 'auto' }} p="xs">
                  <pre>{JSON.stringify(previewData?.sent, null, 4)}</pre>
                </Code>
              </>}
            </Stack>
          </Card.Section>
          <Card.Section px="md" py="sm">
            <Group justify="right">
              <Button variant="light" color="gray" onClick={() => setActiveTab('details')}>
                Details
              </Button>
              <ResourceForm.SubmitButton disabled={!previewData && !record?.data || hasError} />
            </Group>
          </Card.Section>
        </Card>
      </Tabs.Panel>
    </Tabs>
  </ResourceForm>
}
