import { AuthBindings } from '@refinedev/core';
import * as Sentry from '@sentry/browser';
import { zippyDataProvider } from './zippyDataProvider';
import { API_URL } from '../../pages/_app';
import { IProfileRecord } from '@interfaces';

type AuthFnReturnType = (profile?: IProfileRecord) => AuthBindings;

export const authProvider: AuthFnReturnType = (profile) => ({
  login: async ({ email, password, remember = true, redirectPath }) => {
    try {
      const client = await zippyDataProvider(API_URL);
      await client.custom({
        url: `${API_URL}/sanctum/csrf-cookie`,
        method: 'get',
      });

      const result = await client.custom({
        url: `${API_URL}/login`,
        payload: { email, password, remember },
        method: 'post',
      });

      if (result.data?.two_factor) {
        return {
          success: true,
          redirectTo: undefined,
          two_factor: true,
        }
      }

      return {
        success: true,
        redirectTo: redirectPath || '/',
        two_factor: false,
      }
    } catch(e) {
      console.log('login error', e);
      return {
        success: false,
        error: {
          message: e.message,
          name: 'Login Error',
        }
      };
    }
  },

  logout: async () => {
    await zippyDataProvider(API_URL).custom({
      method: 'post', url: `${API_URL}/logout`,
    });

    Sentry.setUser(null);

    return {
      success: true,
      redirectTo: '/login'
    }
  },

  forgotPassword: async ({ email }) => {
    try {
      const client = await zippyDataProvider(API_URL);
      await client.custom({
        url: `${API_URL}/forgot-password`,
        payload: { email },
        method: 'post',
      });

      return {
        success: true,
        redirectTo: '/login'
      }
    } catch(e) {
      return {
        success: false,
        error: {
          message: 'Forgot Password Error',
          name: e.message,
        }
      };
    }
  },

  updatePassword: async (params) => {
    try {
      const client = await zippyDataProvider(API_URL);
      await client.custom({
        url: `${API_URL}/reset-password`,
        payload: params,
        method: 'post',
      });

      return {
        success: true,
        redirectTo: '/login'
      }
    } catch(e) {
      return {
        success: false,
        error: {
          message: 'Forgot Password Error',
          name: e.message,
        }
      };
    }
  },

  onError: async (error) => {
    if (error && error.statusCode === 401) {
      return {
        redirectTo: '/login',
        logout: true,
        error
      }
    }

    return {};
  },

  check: async (ctx) => {
    try {
      const auth = await zippyDataProvider(API_URL, ctx?.req?.cookies)
        .custom<{ is_authenticated: boolean }>({
          method: 'get', url: `${API_URL}/auth/check`,
        });

      if (auth.data.is_authenticated) {
        return {
          authenticated: true,
        }
      }
    } catch (e) {}

    return {
      authenticated: false,
      redirectTo: "/login",
      logout: true,
      error: {
        message: "Check failed",
        name: "User not found",
      }
    };
  },

  getPermissions: async () => {
    if (profile) {
      return profile.roles;
    }

    return null
  },

  getIdentity: async (params) => {
    // TODO figure out how to return initial data and enabled data refetching
    return profile;

    try {
      const user = await zippyDataProvider(API_URL).custom({
        method: 'get', url:`${API_URL}/user/profile`,

      });

      if (user) {
        return user.data;
      }
    } catch (e) {}

    return null
  },
});
