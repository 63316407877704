import { Grid, TextInput } from '@mantine/core';
import { ITagRecord } from '@interfaces';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';

interface Props {
  tag: ITagRecord;
}

export const TagDetails = ({ tag }: Props) => {
  const hasAlias = tag.asset_id !== tag.identifier;

  return <>
    <Grid gutter="xs">
      <Grid.Col span={{ md: 6 }}>
        <TextInput label="Type" value={tag.tag_type?.type} readOnly />
      </Grid.Col>

      <Grid.Col span={{ md: 6 }}>
        <TextInput label="Account Name" value={tag.account_name} readOnly />
      </Grid.Col>

      <Grid.Col span={{ md: 12 }}>
        <TextInput label="Name" value={tag.name} readOnly />
      </Grid.Col>

      <Grid.Col span={{ xs: 6, sm: hasAlias ? 4 : 6, md: hasAlias ? 4 : 6 }}>
        <TextInput label={hasAlias ? 'Asset ID' : 'Asset / Tag ID'} value={tag.asset_id} readOnly />
      </Grid.Col>

      { hasAlias && <>
        <Grid.Col span={{ xs: 6, sm: 4, md: 4 }}>
          <TextInput label="Tag ID" value={tag.identifier} readOnly />
        </Grid.Col>
      </>}

      <Grid.Col span={{ xs: 6, sm: hasAlias ? 4 : 6, md: hasAlias ? 4 : 6 }}>
        <TextInput label="Location ID" value={tag.location_id} readOnly />
      </Grid.Col>

      <Grid.Col span={{ md: 12 }}>
        <TextareaAutosize label="Location Name" value={tag.location_name} readOnly />
      </Grid.Col>

      <Grid.Col span={{ md: 12 }}>
        <TextareaAutosize label="Location" value={tag.address?.formatted_address} readOnly />
      </Grid.Col>

      <Grid.Col>
        <TextareaAutosize label="Description" value={tag.description} readOnly />
      </Grid.Col>
    </Grid>
  </>;
}
