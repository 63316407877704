import { zippyDataProvider } from './zippyDataProvider';

export const pusherConfigProvider = () => {
  return {
    clientKey: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
    cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
    triggerEndpoint: `${process.env.NEXT_PUBLIC_API_URL}/broadcasting/auth`,
    authEndpoint: `${process.env.NEXT_PUBLIC_API_URL}/broadcasting/auth`,
    forceTLS: true,
    authorizer: (channel, options) => ({
      authorize: async (socketId, callback) => {
        const client = await zippyDataProvider(process.env.NEXT_PUBLIC_API_URL);
        const result = await client.custom({
          url: `${process.env.NEXT_PUBLIC_API_URL}/broadcasting/auth`,
          method: 'post',
          payload: {
            socket_id: socketId,
            channel_name: channel.name
          }
        });

        callback(null, result.data);
      }
    }),
  };
}