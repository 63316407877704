import { EuiCallOut } from '@elastic/eui';
import { Button, Divider, Group, Loader, Paper, SimpleGrid, Text } from '@mantine/core';
import { useFeature } from 'flagged';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IRequestTypePresetOptionItem, IRequestTypePresetRecord } from '@interfaces';
import { useState } from 'react';

interface Props {
  presets: IRequestTypePresetOptionItem[]
}

export const CommonlyUsedTypes = ({ presets = [] }: Props) => {
  const isAdmin = useFeature('admin')
  const { submit } = useResourceForm();

  const [presetSavingId, setPresetSavingId] = useState<number>(null);

  if (isAdmin || !presets.length) {
    return <></>;
  }

  const handleSubmit = ({ id, ...preset}: IRequestTypePresetRecord) => {
    setPresetSavingId(id);
    submit(preset);
  };

  return <>
    <EuiCallOut
      title="Select from common request types"
      iconType="help"
    >
      <Text size="sm" mb="sm">
        Below are the most commonly used request types. Click add to add that request to your customer app. You can also create your own request type below.
      </Text>

      <SimpleGrid
        cols={{ sm: 2, md: 3 }}
        spacing={8}
      >
        {presets.map((preset, i) => (
          <Paper bg="white" p={8} key={i}>
            <Group gap={8} wrap="nowrap">
              <Button size="xs" variant="filled"
                      disabled={presetSavingId === preset.id}
                      onClick={() => handleSubmit(preset)}>
                { presetSavingId === preset.id ? <Loader size="xs" color="gray" /> : 'Add' }
              </Button>
              <Text size="sm" fw="500" mb={0}>{preset.type}</Text>
            </Group>
          </Paper>
        ))}
      </SimpleGrid>
    </EuiCallOut>

    <Divider />
  </>;
}
