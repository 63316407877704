import { IProfileRecord } from '@interfaces';

type FeatureFlagFnReturnType = (
  profile?: IProfileRecord
) => { [key: string]: boolean };

export const featureFlagControlProvider: FeatureFlagFnReturnType = (profile) => {
  const flags = {
    'refunds.has_requests': true,
    refunds: true,
    'refunds.has_vopay': false,
    'refunds.has_automation': false,
    voice_messages: true,
    'voice_messages.has_requests': true,
    webhooks: true,
    integrations: true,
    wallet: false,
  };

  if (profile) {
    flags['admin'] = profile.roles.includes('Admin');
    flags['owner'] = profile.roles.includes('Owner');

    if (!flags['admin']) {
      flags['refunds.has_requests'] = profile.owner.features.refunds || profile.owner.meta.has_refund_requests;
      flags.refunds = Boolean(profile.owner.features.refunds);
      flags['refunds.has_vopay'] = profile.owner.settings.refunds_allow_vopay;
      flags['refunds.has_automation'] = profile.owner.settings.refunds_allow_automation;
      flags.voice_messages = profile.owner.features.voice_messages;
      flags['voice_messages.has_requests'] = profile.owner.features.voice_messages || profile.owner.meta.has_voice_messages;
      flags.webhooks = profile.owner.features.webhooks;
      flags.integrations = profile.owner.features.integrations;

      flags.wallet = profile.owner.stripe_id && flags.refunds;
      flags['wallet.has_credit'] = Boolean(flags.wallet && profile.owner?.wallet?.meta?.credit > 0);
      flags['wallet.has_no_credit'] =  flags.wallet && !flags['wallet.has_credit'];
      flags['wallet.has_transactions'] = flags.wallet || profile.owner.meta.has_transactions
    }

    flags['help.create'] = profile.permissions.includes('help.create');
    flags['refund.create'] = profile.permissions.includes('refund.create') && flags.refunds;
    flags['refund.update'] = profile.permissions.includes('refund.update') && flags.refunds;
    flags['refund.disbursement'] = profile.permissions.includes('refund.disbursement') && flags.refunds;
    flags['request.update'] = profile.permissions.includes('request.update');
    flags['settings.refunds'] = profile.permissions.includes('settings.refunds');
    flags['wallet.update'] = profile.permissions.includes('wallet.update');

    flags['demo'] = profile.meta.can_trigger_demo_data ?? false;
    flags['has_vms_sync'] = profile.meta.can_trigger_vms_sync ?? false;
  }

  return flags;
};
