import { EuiBasicTableColumn } from '@elastic/eui';
import { IRequestLinkListItem } from '@interfaces';
import { DateTime } from '@components/ui/DateTime';
import { ActionMenu } from '@components/table/actions/ActionMenu';
import { EditRequestAction } from '@components/table/actions/EditRequestAction';
import { Feature } from 'flagged';
import { CreateRequestAction } from '@components/table/actions/CreateRequestAction';
import { DeleteRequestLinkAction } from '@components/table/actions/DeleteRequestLinkAction';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'type',
    name: 'Type',
    sortable: true,
  },
  {
    field: 'customer_name',
    name: 'To',
    sortable: true,
  },
  {
    field: 'tag_identifier',
    name: 'Tag ID',
    sortable: true,
  },
  {
    field: 'user_name',
    name: 'Created By',
    sortable: true,
  },
  {
    field: 'viewed_at',
    name: 'Viewed',
    dataType: 'date',
    width: '165',
    sortable: true,
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'created_at',
    name: 'Sent',
    dataType: 'date',
    width: '165',
    sortable: true,
    render: (date) => <DateTime value={date} />
  },
  {
    name: '',
    width: '40',
    actions: [
      {
        render: (record) => <ActionMenu id={record.id}>
          <DeleteRequestLinkAction id={record.id} disabled={!!record.deleted_at} />
        </ActionMenu>
      },
    ]
  },
] as EuiBasicTableColumn<IRequestLinkListItem>[];
