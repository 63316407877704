import { PropsWithChildren } from 'react';
import { useIdentity } from '@components/data/Identity.context';
import { useRefundRequestFormContext } from '../../data/cards/RefundRequestCard/RefundRequestForm.context';
import { IRefundRecord } from '@interfaces';
import { NotificationPreview } from '@components/ui/notificationPreviewers/NotificationPreview';

interface Props {
  refund: IRefundRecord;
}

export const RefundNotificationPreview = ({ refund, children }: PropsWithChildren<Props>) => {
  const form = useRefundRequestFormContext();
  const { identity } = useIdentity();

  const translations = identity.owner?.translations?.[refund.request.customer_locale]?.sms;

  let message = '';
  if (form.values.status === 'Approved') {
    if (refund.method === 'Reversal') {
      message = translations?.refund?.approved_reversal_method;
    } else if (['Cash', 'Check'].includes(refund.method)) {
      message = translations?.refund?.approved_cash_method;
    } else if (['Venmo', 'Paypal', 'VendorCard', 'PayRange', 'Zelle', 'Interac'].includes(refund.method)) {
      message = translations?.refund?.approved_online_method;
    }
  } else if (form.values.status === 'Declined') {
    message = translations?.refund?.declined;
  }

  message = message
    .replace(':name', refund.customer.name)
    .replace(':customer_name', refund.customer.name)
    .replace(':refund_method', refund.refund_name)
    .replace(':owner_name', identity.owner?.name)
    .replace(':additional_note', form.values.status === 'Approved' ? form.values.approved_decision_note : form.values.declined_decision_note)

  return <>
    <NotificationPreview message={message}>
      {children}
    </NotificationPreview>
  </>;
}
