import { IResourceComponentsProps, useResource } from '@refinedev/core';
import {
  IIntegrationResponse,
  IIntegrationSeedRecord,
  IIntegrationVendSysRecord,
  IIntegrationVoPayRecord
} from '@interfaces';
import { Box, Button, Card, Container, Grid, Group, List, Loader, Select, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Feature } from 'flagged';
import { useGoBackToList, useOwnerOptions } from '@components/hooks';
import { VendSysDetails } from './forms/VendSysDetails';
import { IconArrowLeft } from '@tabler/icons-react';
import { ResourceFormProvider } from '@components/ui/form/ResourceForm.context';
import { VoPayDetails } from './forms/VoPayDetails';
import { useIdentity } from '@components/data/Identity.context';
import { SeedDetails } from './forms/SeedDetails';
import { EuiCallOut } from '@elastic/eui';

export function IntegrationEdit({ initialData: record }: IResourceComponentsProps<IIntegrationResponse>) {
  const { identity } = useIdentity();
  const { resource } = useResource();
  const { goBack } = useGoBackToList();
  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();

  const form = useForm({
    initialValues: {
      owner_id: record?.data.owner_id?.toString() ?? null,
      platform: record?.data.platform ?? '',
      credentials: {
        api_key: record?.data.credentials.api_key ?? '',
        api_token: record?.data.credentials.api_token ?? '',
      },
    },
  });

  const platformOptions = [
    { value: 'VendSys', label: 'VendSys' },
    { value: 'Seed', label: 'Cantaloupe Seed' },
  ];

  if (identity.roles.includes('Admin')) {
    platformOptions.push({ value: 'VoPay', label: 'Interac' });
  }

  return <Container size="sm" px={0}>
    <Button
      leftSection={<IconArrowLeft size={18} />}
      size="sm"
      variant="subtle"
      onClick={() => goBack(resource.name)}
      mb="xs"
    >Back</Button>

    <Group mb="lg">
      <Title order={2}>Integration</Title>
    </Group>

    { form.values.platform === 'Seed' && <Box mb="md">
      <EuiCallOut
        title="How to get Seed API Credentials"
        iconType="help"
      >
        <List type="ordered" size="sm" styles={{ item: { lineHeight: 1.8 } }}>
          <List.Item>Send a request to your Cantaloupe Account Manager to enable the ZippyAssist integration in Seed.</List.Item>
          <List.Item>You will need to provide the email address and cellphone number of the person who will enter the credentials for the integration into ZippyAssist.</List.Item>
          <List.Item>Cantaloupe will send the username and password to that individual so they can complete the configuration in ZippyAssist.</List.Item>
        </List>
      </EuiCallOut>
    </Box>}

    <Card shadow="sm" radius="sm">
      <Card.Section p="md">
        <Stack>
          <Grid>
            <Feature name="admin">
              <Grid.Col span={{ md: 6 }}>
                <Select
                  label="Assign to owner"
                  placeholder="Select an owner..."
                  searchable
                  leftSection={isOwnersLoading && <Loader size="xs" />}
                  data={owners?.data || []}
                  disabled={!!record?.data.owner_id}
                  { ...form.getInputProps('owner_id') }
                />
              </Grid.Col>
            </Feature>

            <Grid.Col span={{ md: 'auto' }}>
              <Select data={platformOptions}
                      label="Type"
                      disabled={!!record?.data.platform}
                      {...form.getInputProps(`platform`)} />
            </Grid.Col>
          </Grid>

        </Stack>
      </Card.Section>

      <ResourceFormProvider form={form as any}>
        { form.values.platform === 'VendSys' && <VendSysDetails record={record?.data as IIntegrationVendSysRecord} /> }
        { form.values.platform === 'Seed' && <SeedDetails record={record?.data as IIntegrationSeedRecord} /> }
        { form.values.platform === 'VoPay' && <VoPayDetails record={record?.data as IIntegrationVoPayRecord} /> }
      </ResourceFormProvider>
    </Card>
  </Container>
}
