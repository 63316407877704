import { EuiBasicTableColumn } from '@elastic/eui';
import { IWebhookLogListItem } from '@interfaces';
import { DateTime } from '@components/ui/DateTime';
import { Badge } from '@mantine/core';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'name',
    name: 'Name',
    truncateText: true,
  },
  {
    field: 'event_id',
    name: 'Event ID',
    truncateText: true,
  },
  {
    field: 'event_type',
    name: 'Type',
    truncateText: true,
    render: (status) => <Badge tt="none" p="xs" color="blue">{status}</Badge>,
  },
  {
    field: 'version',
    name: 'version',
    width: '100',
    render: (status) => <Badge tt="none" p="xs" color="orange">{status}</Badge>,
  },
  {
    field: 'status',
    name: 'Status',
    width: '100',
    render: (status) => <Badge tt="none" p="xs" color={status >= 200 && status < 300  ? 'green' : 'red'}>{status}</Badge>,
  },
  {
    field: 'created_at',
    name: 'Sent',
    dataType: 'date',
    width: '165',
    render: (date) => <DateTime value={date} />
  },
] as EuiBasicTableColumn<IWebhookLogListItem>[];
