import { IResourceComponentsProps } from "@refinedev/core";
import { EuiBasicTableColumn } from '@elastic/eui';
import { ITransactionListItem, ITransactionListResponse } from '@interfaces';
import { ResourceTable } from '@components/table/ResourceTable';
import { Badge } from '@mantine/core';
import { FilterBar } from '@components/filters/FilterBar';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { DateTime } from '@components/ui/DateTime';
import { FilterSelect } from '@components/filters/FilterSelect';
import { Feature } from 'flagged';
import { FilterText } from '@components/filters/FilterText';
import { TransactionTable } from './table/TransactionTable';
import { RefundTable } from '../../refund/table/RefundTable';

export function TransactionList({ initialData }: IResourceComponentsProps<ITransactionListResponse>) {
  const filters = <>
    <FilterSelect name="type" label="Type" route="transaction_types" />
  </>;

  return <TableFilterProvider>
    <FilterBar filters={filters} />
    <TransactionTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
