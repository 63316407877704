import { IResourceComponentsProps } from "@refinedev/core";
import { IRefundMethodResponse } from '@interfaces';
import { Card, Divider, Grid, Group, Loader, NumberInput, Select, SimpleGrid, Stack, Switch, TextInput, Input, Title, Text } from '@mantine/core';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { Feature, useFeature } from 'flagged';
import { useIdentity } from '@components/data/Identity.context';
import { useOwnerOptions } from '@components/hooks';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useDidUpdate } from "@mantine/hooks";
import { EuiCallOut } from '@elastic/eui';
import { Translatable } from '@components/ui';

export function RefundMethodEdit({ initialData: record }: IResourceComponentsProps<IRefundMethodResponse>) {
  const { identity } = useIdentity();
  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();
  const isAdmin = useFeature('admin');
  const hasVoPayAutomation = useFeature('refunds.has_vopay');

  const languages = identity?.owner?.supported_languages ?? [];

  const translations = record?.data.translations || {
    name: {
      en: '',
      es: '',
      fr: ''
    },
    description: {
      en: '',
      es: '',
      fr: ''
    }
  };

  const schema = Yup.object().shape({
    roles: Yup.array(),
    owner_id: Yup.mixed().nullable().when('roles', {
      is: (roles) => roles.includes('Admin'),
      then: (schema) => schema.required('Assign an owner')
    }),
    type: Yup.string().required(),
    name: Yup.string().when(['type', 'is_custom_name'], {
      is: (type, isCustomName) => type === 'VendorCard' || isCustomName,
      then: (schema) => schema.required('Enter a name')
    }),
    is_custom_name: Yup.bool(),
    is_active: Yup.bool(),
    settings: Yup.object().when('type', ([type], schema) => {
      return schema.shape({
        'vendorcard_maxlength': Yup.number().min(1).max(100),
        'vendorcard_account-identifier': Yup.string().nullable().when({
          is: () => type === 'VendorCard',
          then: (schema) => schema.required('Select an account identifier')
        }),
      });
    }),
    translations: Yup.lazy((_, { parent }) => Yup.object({
      name: Yup.lazy(() => {
        const rules = (identity?.owner?.supported_languages || []).reduce((rules, lang) => {
          rules[lang] = Yup.string().nullable().when({
            is: () => parent.type === 'VendorCard' && identity?.owner?.supported_languages.length > 1,
            then: (schema) => schema.required('Text is required'),
          });

          return rules;
        }, {});

        return Yup.object(rules).required();
      }),
      description: Yup.object(),
    })),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      roles: identity.roles,
      owner_id: record?.data.owner_id.toString() ?? null,
      type: record?.data.type ?? '',
      name: record?.data.name ?? '',
      is_custom_name: record?.data.type !== 'VendorCard' ? !!record?.data.name : false,
      is_active: record?.data.is_active ?? true,
      settings: {
        'vendorcard_maxlength': record?.data.settings['vendorcard_maxlength'] ?? 16,
        'vendorcard_account-identifier': record?.data.settings['vendorcard_account-identifier'] ?? null,
      },
      translations: {
        name: {
          en: translations.name?.en ?? '',
          es: translations.name?.es ?? '',
          fr: translations.name?.fr ?? ''
        },
        description: {
          en: translations.description?.en ?? '',
          es: translations.description?.es ?? '',
          fr: translations.description?.fr ?? ''
        }
      },
    },
  });

  const isUSOwner = identity.owner?.country_code === 'US';
  const isCAOwner = identity.owner?.country_code === 'CA';

  const methodTypes = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Check', label: 'Check' },
    { value: 'Paypal', label: 'PayPal' },
    { value: 'Venmo', label: 'Venmo' },
    { value: 'Zelle', label: 'Zelle' },
    { value: 'Interac', label: 'Interac' },
    { value: 'VendorCard', label: 'Vendor Card' },
  ].filter(type => {
    if (isAdmin) return true;

    if (isCAOwner && ['Zelle', 'Venmo'].includes(type.value)) {
      return false;
    } else if (isUSOwner && ['Interac'].includes(type.value)) {
      return false;
    }

    return true;
  });

  const setTranslation = () => {
    const language = languages[0];

    if (form.values.name && !form.values.translations?.name[language]) {
      form.setFieldValue('translations.name', { ...form.values.translations.name, [language]: form.values.name });
    }
  };

  useDidUpdate(() => {
    form.setFieldValue('is_custom_name', false);
    form.setFieldValue('name', '');
  }, [form.values.type]);

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>Refund Method</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <Stack>
          { form.values.type === 'Zelle' && <>
            <EuiCallOut
              title={<Text size="sm" fw="500" span>Zelle refunds will incur a fee of $0.40 per transaction — non-refundable.</Text>}
              iconType="help"
              color="primary">
            </EuiCallOut>
          </>}

          { form.values.type === 'Interac' && hasVoPayAutomation && <>
            <EuiCallOut
              title={<Text size="sm" fw="500" span>Interac refunds will incur a fee of $0.85 per transaction — non-refundable.</Text>}
              iconType="help"
              color="primary">
            </EuiCallOut>
          </>}

          { (form.values.type === 'Venmo' || form.values.type === 'Paypal') && <>
            <EuiCallOut
              title={<Text size="sm" fw="500" span>{form.values.type} refunds will incur a fee of ${isUSOwner ? 0.25 : 0.32} per transaction — refundable for returned refunds (expired unclaimed).</Text>}
              iconType="help"
              color="primary">
            </EuiCallOut>
          </>}

          <Grid>
            <Feature name="admin">
              <Grid.Col span={{ md: 6 }}>
                <Select
                  label="Assign to owner"
                  placeholder="Select an owner..."
                  searchable
                  leftSection={isOwnersLoading && <Loader size="xs" />}
                  data={owners?.data || []}
                  { ...form.getInputProps('owner_id') }
                />
              </Grid.Col>
            </Feature>

            <Grid.Col span={{ md: 6 }}>
              <Select data={methodTypes}
                      label="Type"
                      disabled={!!record?.data.type}
                      {...form.getInputProps(`type`)} />
            </Grid.Col>

            { (form.values.is_custom_name || form.values.type === 'VendorCard') && <>
              <Grid.Col span={{ md: 6 }}>
                <TextInput label="Name"
                           description="Provide a name that will be shown in the dashboard"
                           {...form.getInputProps('name')}
                           onBlur={setTranslation} />
              </Grid.Col>

              { languages.length > 1 && <>
                <Grid.Col span={{ md: 12 }}>
                  <Input.Label mb={0}>Name Translations</Input.Label>
                  <Input.Description>Provide a name that will be shown to the customer</Input.Description>
                  <Translatable field="translations.name" limit={35} />
                </Grid.Col>
              </>}
            </>}

            <Grid.Col span={{ md: 12 }}>
              <Input.Label mb={0}>Description Translations</Input.Label>
              <Input.Description>Provide an optional description that will be shown to the customer</Input.Description>
              <Translatable field="translations.description" limit={75} />
            </Grid.Col>

            { form.values.type === 'VendorCard' && <>
              <Grid.Col span={{ md: 6 }}>
                <Select
                  label="Account Identifier"
                  description="How will the customer identify their account?"
                  placeholder="Select a format..."
                  searchable
                  data={[
                    { value: 'CardNumber', label: 'Card Number' },
                    { value: 'Email', label: 'Email' },
                    { value: 'PhoneNumber', label: 'Phone Number' },
                  ]}
                  { ...form.getInputProps('settings.vendorcard_account-identifier') }
                />
              </Grid.Col>

              { form.values.settings['vendorcard_account-identifier'] === 'CardNumber' && <>
                <Grid.Col span={{ md: 6 }}>
                  <NumberInput label="Number of digits"
                               description="What is the length of the card number?"
                               { ...form.getInputProps('settings.vendorcard_maxlength') } />
                </Grid.Col>
              </>}
            </>}
          </Grid>

          <Divider />

          <SimpleGrid cols={{ sm: 2 }} spacing="sm">
            { ['Cash', 'Check'].includes(form.values.type) && <>
              <Switch label="Alternative name?"
                      description="Use an alternative name instead of the selected type"
                      offLabel="No" onLabel="Yes"
                      { ...form.getInputProps('is_custom_name', { type: 'checkbox' }) }
              />
            </>}

            <Switch label="Enabled"
                    description="Marks this method as enabled or disabled"
                    offLabel="No" onLabel="Yes"
                    { ...form.getInputProps('is_active', { type: 'checkbox' }) }
            />
          </SimpleGrid>
        </Stack>
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
