import { IResourceComponentsProps } from "@refinedev/core";
import { ITagListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterSelect } from '@components/filters/FilterSelect';
import { TagByAccountTable } from './table/TagByAccountTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { FilterSingleRange } from '@components/filters/FilterSingleRange';
import { Feature } from 'flagged';
import { EuiCallOut } from "@elastic/eui";
import { Space, Text } from '@mantine/core';

export function TagByAccountList({ initialData }: IResourceComponentsProps<ITagListResponse>) {
  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterSingleRange name="help_count" label="Help" min={0} max={200} />
    <FilterSingleRange name="feedback_count" label="Feedback" min={0} max={200} />
    <Feature name="refunds">
      <FilterSingleRange name="refunds_count" label="Refunds" min={0} max={200} />
    </Feature>
    <Feature name="voice_messages">
      <FilterSingleRange name="voice_count" label="Calls" min={0} max={200} />
    </Feature>
  </>;

  return <TableFilterProvider>
    <FilterBar filters={filters} />

    <EuiCallOut title={<Text size="sm" fw="500" span>NOTE: This information is derived from an equipment item’s Account Name.</Text>} iconType="help"></EuiCallOut>

    <Space h={16} visibleFrom="sm" />

    <TagByAccountTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
